import { complimentaryChapterOrderSet } from '../../../shared/utils';

function activityMapper(item) {
  let temp = null;
  const activity = item?.activityId?.split('-')[1];
  const status = item?.status;
  if (status !== 'completed') return temp;
  switch (activity) {
    case 'concept':
      temp = 'Concept';
      break;
    case 'video':
      temp = 'Video';
      break;
    case 'assignment':
      temp = 'Assignment';
      break;
    case 'coding_assessment':
      temp = 'Code';
      break;
    case 'quiz':
      temp = 'Quiz';
      break;
    case 'peer_reviewed':
      temp = 'Peer';
      break;
    default:
      break;
  }
  return temp;
}

export function currentLessonActivityStatus(lessonData) {
  try {
    return lessonData?.activities?.map((i) => {
      return activityMapper(i);
    });
  } catch (error) {
    console.error(error);
  }
}

function courseInfoGetter(chapterData) {
  return chapterData.map((i) => {
    const temp = [];

    if (i?.gistUrl) {
      temp.push('Concept-Colab');
    } else if (i?.notion_id) {
      temp.push('Concept-Notion');
    } else if (i?.content) {
      temp.push('Concept');
    }

    if (i?.videoUrl || i?.cdnVideoUrl) {
      temp.push('Video');
    }
    if (i?.courseProblems) {
      temp.push('Code');
    }
    if (i?.quizAssesments) {
      temp.push('Quiz');
    }
    if (i?.learn_assignment?.data) {
      temp.push('Assignment');
    }
    if (i?.peer_review_publication) {
      temp.push('Peer');
    }

    if (i?.multimedia) {
      const multimediaNames = i.multimedia.map((j) => `Multimedia-${j?.name}`);
      temp.push(...multimediaNames);
    }
    return temp;
  });
}

export function getCourseInfo(chapterData, name, bit) {
  const last = formattedRouteSecondLast();
  const currentLessonIndex = name
    ? chapterData?.findIndex((i) => i?.lesson_slug === name)
    : chapterData.findIndex((i) => i.lesson_slug === last);
  const currentChapter = chapterData[currentLessonIndex]?.chapterId;
  const arr = courseInfoGetter(chapterData);
  return { navigation: arr, id: currentLessonIndex, currentChapter };
}

export const getchapterData = (learnTabSlice) => {
  const last = formattedRouteSecondLast(window.location.pathname);
  const filterData = learnTabSlice?.filter((i) =>
    i?.attributes?.learn_lessons?.data?.some((j) => j?.attributes?.slug === last)
  );
  const sortedData = complimentaryChapterOrderSet(filterData?.[0]);
  const data = sortedData?.attributes?.learn_lessons?.data
    ?.map((j) => ({
      ...j?.attributes,
      courseProblems: j?.attributes?.coding_assessment?.data,
      quizAssesments: j?.attributes?.learn_assessment?.data,
      lesson_name: j?.attributes?.name,
      lesson_slug: j?.attributes?.slug,
      lessonId: j?.id,
      chapterId: sortedData?.id,
      chapterName: sortedData?.attributes?.Name,
      solvedStatus: j?.solvedStatus,
    }))
    .flat();
  return data;
};

export const getCourseData = (learnTabSlice, courseId) => {
  const data = learnTabSlice
    ?.map((j) =>
      j.attributes.learn_chapters?.data?.map((k) =>
        k.attributes?.learn_lessons?.data?.map((t) => ({
          ...t?.attributes,
          courseProblems: t?.attributes?.coding_assessment?.data,
          quizAssesments: t?.attributes?.learn_assessment?.data,
          lesson_slug: t?.attributes?.slug,
          lesson_name: t?.attributes?.name,
          chapterId: k?.id,
          lessonId: t?.id,
          moduleId: j?.id,
          moduleSlug: j?.attributes?.slug,
          moduleName: j?.attributes?.name,
          chapterSlug: k?.attributes?.slug,
          chapterName: k?.attributes?.name,
          courseId,
        }))
      )
    )
    .flat()
    .flat();
  return data;
};

export const getSolvedLessonCountForCourseData = (course) => {
  const data = course?.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        solvedLessonsCount: cur?.attributes?.solvedLessonsCount,
        totalLessonsCount: cur?.attributes?.totalLessonsCount,
      },
    }),
    {}
  );
  return data;
};

export const formattedRoute = () => {
  const url = window.location.pathname.split('/');
  return url.splice(0, url?.length - 2).join('/');
};

export const formattedRouteLast = () => {
  const url = window.location.pathname.split('/');
  return url[url?.length - 1];
};

export const formattedRouteSecondLast = () => {
  const url = window.location.pathname.split('/');
  return url[url?.length - 2];
};

export const getLastLessonData = (courseData, data, index, type) => {
  return {
    courseId: courseData?.courseId,
    moduleId: data?.[index]?.moduleId,
    chapterId: data?.[index]?.chapterId,
    lessonId: data?.[index]?.lessonId,
    trackId: courseData?.id,
    type: type,
  };
};

export const getCurrentLesson = (courseData, data, index, type) => {
  return {
    courseId: courseData?.courseId || courseData?.courseId,
    moduleId: data?.[index + 1]?.moduleId || data?.[index]?.moduleId,
    chapterId: data?.[index + 1]?.chapterId || data?.[index]?.chapterId,
    lessonId: data?.[index + 1]?.lessonId || data?.[index]?.lessonId,
    trackId: courseData?.id,
    type: type,
  };
};

export const getLastLessonShort = (data, index) => {
  return {
    courseId: data?.[index]?.chapterId,
    moduleId: data?.[index]?.moduleId,
    lessonId: data?.[index]?.lessonId,
    type: 'short',
  };
};

export const getCurrentLessonShort = (data, index) => {
  return {
    courseId: data?.[index]?.chapterId,
    moduleId: data?.[index + 1]?.moduleId || data?.[index]?.moduleId,
    lessonId: data?.[index + 1]?.lessonId || data?.[index]?.lessonId,
    type: 'short',
  };
};

export function areArraysMatching(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}
