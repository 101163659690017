import dayjs from 'dayjs';
import { COURSE_TYPE } from 'src/constants';
import { leadSquareForEventRegistration } from '../../../../../services/homeTabApiService';

export const nameConverter = (e) => {
  return e?.split(' ')?.join('-');
};

export const firstChapter = (data) => {
  let temp = '';
  if (data?.gistUrl) {
    temp = 'Concept-Colab';
  } else if (data?.notion_id) {
    temp = 'Concept-Notion';
  } else if (data?.content) {
    temp = 'Concept';
  } else if (data?.videoUrl || data?.cdnVideoUrl) {
    temp = 'Video';
  } else if (data?.coding_assessments?.data) {
    temp = 'Code';
  } else if (data?.learn_assessments?.data) {
    temp = 'Quiz';
  } else if (data?.learn_project?.data) {
    temp = 'Project';
  } else if (data?.peer_review_publication) {
    temp = 'Peer';
  } else if (data?.learn_assignment?.data) {
    temp = 'Assignment';
  } else if (data?.multimedia) {
    temp = `Multimedia-${data?.multimedia?.[0]?.name}`;
  }
  return temp;
};

export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const dDisplay = d > 0 ? d : 0;
  const hDisplay = h > 0 ? h : 0;
  const mDisplay = m > 0 ? m : 0;
  return [dDisplay, hDisplay, mDisplay];
}

export function chapterOrderSet(courseData) {
  try {
    const modules = courseData?.course_modules?.data ?? courseData?.learn_modules?.data ?? [];
    const order = courseData?.moduleOrder?.split('\n') ?? [];
    const filterNoChapterModules = modules.filter(
      (item) => item?.attributes?.learn_chapters?.data?.length > 0
    );
    const sortedData = filterNoChapterModules
      .slice()
      .sort((a, b) => order.indexOf(a?.attributes?.slug) - order.indexOf(b?.attributes?.slug));

    const chapterOrder = sortedData.map((item) => {
      try {
        return item?.attributes?.chapterOrder?.split('\n') || [];
      } catch (error) {
        return [];
      }
    });
    const orderedChapter = sortedData.map((item, index) => {
      try {
        const learnChaptersWithLessons = item?.attributes?.learn_chapters?.data?.filter(
          (item) => item?.attributes?.learn_lessons?.data?.length > 0
        );
        const orderedLearnChapters = learnChaptersWithLessons
          ?.slice()
          ?.sort(
            (a, b) =>
              chapterOrder[index]?.indexOf(a?.attributes?.slug) -
              chapterOrder[index]?.indexOf(b?.attributes?.slug)
          );
        return {
          ...item,
          attributes: {
            ...item.attributes,
            learn_chapters: {
              data: orderedLearnChapters,
            },
          },
        };
      } catch (error) {
        return item;
      }
    });

    const lessonOrder = orderedChapter.map((item, index) => {
      try {
        const learnChaptersWithLessons = item?.attributes?.learn_chapters?.data?.filter(
          (item) => item?.attributes?.learn_lessons?.data?.length > 0
        );
        return learnChaptersWithLessons?.map((chapter) => {
          try {
            return chapter?.attributes?.lessonOrder?.split('\n') || [];
          } catch (error) {
            return [];
          }
        });
      } catch (error) {
        return [];
      }
    });

    const orderedLesson = orderedChapter.map((item, index) => {
      try {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            learn_chapters: {
              data: item?.attributes?.learn_chapters?.data?.map((chapter, ind) => {
                try {
                  const orderedLearnLessons = chapter?.attributes?.learn_lessons?.data
                    ?.slice()
                    ?.sort(
                      (a, b) =>
                        lessonOrder[index][ind]?.indexOf(a?.attributes?.slug) -
                        lessonOrder[index][ind]?.indexOf(b?.attributes?.slug)
                    );

                  return {
                    ...chapter,
                    attributes: {
                      ...chapter.attributes,
                      learn_lessons: {
                        data: orderedLearnLessons,
                      },
                    },
                  };
                } catch (error) {
                  return chapter;
                }
              }),
            },
          },
        };
      } catch (error) {
        return item;
      }
    });
    const finalData = orderedLesson?.filter(
      (item) => item?.attributes?.learn_chapters?.data?.length > 0
    );
    return {
      ...courseData,
      course_modules: [...finalData],
      learn_modules: [...finalData],
    };
  } catch (error) {
    console.error('An error occurred:', error);
    return courseData;
  }
}

export function complimentaryChapterOrderSet(courseData) {
  if (!courseData?.attributes?.learnChapters?.data?.length) return courseData;
  const chapterOrder = courseData?.attributes?.chapterOrder?.split('\n');
  const sortedChapterData = [...courseData?.attributes?.learnChapters?.data]?.sort((a, b) => {
    return chapterOrder?.indexOf(a?.attributes?.slug) - chapterOrder?.indexOf(b?.attributes?.slug);
  });
  sortedChapterData?.forEach((chapter) => {
    const lessonOrder = chapter?.attributes?.lessonOrder?.split('\n');
    const sortedLessonsData = [...chapter?.attributes?.learn_lessons?.data]?.sort(
      (a, b) =>
        lessonOrder?.indexOf(a?.attributes?.slug) - lessonOrder?.indexOf(b?.attributes?.slug)
    );
    chapter.attributes.learn_lessons.data = sortedLessonsData;
  });
  return {
    ...courseData,
    attributes: {
      ...courseData?.attributes,
      learnChapters: {
        data: sortedChapterData,
      },
    },
  };
}

export function listLastLesson(sortedData, lastLesson) {
  let data = null;
  sortedData?.some((i, ind) =>
    i?.attributes?.learn_chapters?.data?.some((t, index) =>
      t?.attributes?.learn_lessons?.data.some((lesson, id) => {
        if (lesson?.id === parseInt(lastLesson)) {
          data = {
            lessonId: lesson?.id,
            chapterId: index,
            moduleId: ind,
          };
          return true;
        }
        return false;
      })
    )
  );
  return data;
}

export function returnLastLessonName(chapter, lastLesson) {
  const data = {};
  const selectedChapter = chapter?.BASIC_INFO?.learn_tracks?.data?.find(
    (item) => item?.id === parseInt(lastLesson?.trackId)
  );
  // data attributes added in BASIC_INFO only after learn_modules
  if (!selectedChapter)
    return {
      lesson:
        selectedChapter?.attributes?.learn_modules?.data?.[0]?.attributes?.learn_chapters?.data?.[0]
          ?.attributes?.learn_lessons?.data?.[0],
      module: selectedChapter?.attributes?.learn_modules?.data?.[0]?.attributes?.name,
      chapter:
        selectedChapter?.attributes?.learn_modules?.data?.[0]?.attributes?.learn_chapters
          ?.data?.[0],
    };
  selectedChapter?.attributes?.learn_modules?.data?.every((item, ind) => {
    const filtered = item?.attributes?.learn_chapters?.data?.filter((t, index) =>
      t?.attributes?.learn_lessons?.data.some((lesson) => {
        if (lesson?.id === parseInt(lastLesson?.lessonId)) {
          data.lesson = lesson;
          return true;
        }
        return false;
      })
    );
    if (filtered?.length > 0) {
      data.module = item?.attributes?.name;
      data.chapter = item?.attributes?.learn_chapters?.data[ind];
      return false;
    }
    return true;
  });
  return data;
}

export function getRandom(list) {
  return list[Math.floor(Math.random() * list?.length)];
}

export function returnSelectedCourse(course) {
  return course === COURSE_TYPE.DATA_SCIENCE
    ? 'dataScience'
    : course === COURSE_TYPE.DATA_ENGINEERING
    ? 'dataEngineering'
    : course === COURSE_TYPE.WEB_DEV
    ? 'web3'
    : course === COURSE_TYPE.UG_DIPLOMA_CS
    ? 'ugDiplomaCS'
    : course;
}

export const enrollNowRedirect = (courseSelected, history, location) => {
  leadSquareForEventRegistration({
    activityName: 'EnrolNow',
    activityValues: {
      pageUrl: window.location.href,
      goal: courseSelected,
      location,
    },
  });
  history.push(`/${courseSelected}/payment`);
};

export function formatTime(date) {
  const formattedDate = dayjs.utc(date).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}

export const eventsColor = {
  SUCCESS_BACKGROUND: '#EAF9F5',
  SUCCESS: '#3D8D7F',
  ERROR_BACKGROUND: '#F9EAEA',
  ERROR: '#AB3845',
  DISABLED_BACKGROUND: '#E0E0E0',
  DISABLED: '#404040',
};

export const INITIAL_CODE = {
  javascript: `// Add your code in the main function only.
// a & b are example variables of how to take integer and string input
// readline function is for reading input
function main() {
    var a = parseInt(readLine());
    var b = readLine();
    console.log(a);
}`,
  java: `import java.io.*;
import java.util.*;
import java.text.*;
import java.math.*;

// Add you code in the main function only
// a and b are example variables of how to take integer and string input

public class Main {

  public static void main(String[] args) {
        Scanner in = new Scanner(System.in);
        int a;
        a = in.nextInt();
        String b;
        b = in.nextLine();
        System.out.println(a);
    }
}`,

  python: `#write your code inside main function
def  main():
  a = int(input())
  b = int(input())
  
main();`,

  sql: `--Write your Query here`,
};

export const javascriptHiddenCode = `process.stdin.resume();
process.stdin.setEncoding('ascii');
var input_stdin = "";
var input_stdin_array = "";
var input_currentline = 0;
process.stdin.on('data', function (data) {
    input_stdin += data;
});
process.stdin.on('end', function () {
    input_stdin_array = input_stdin.split("\\n");
    main();
});
function readLine() {
    return input_stdin_array[input_currentline++];
}

`;

export function isValidURL(string) {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
}

export function validUrlString(str) {
  if (str?.length > 0) {
    return str.startsWith('https://') ? str : 'https://' + str;
  }
  return str;
}

export const addToCalendar = (data) => {
  let calendarUrl = 'https://calendar.google.com/calendar/render';
  calendarUrl += '?action=TEMPLATE';
  calendarUrl += '&text=' + encodeURIComponent(data?.topic);
  calendarUrl += '&dates=' + formatTime(data?.startTime) + '/' + formatTime(data?.endTime);
  return window.open(calendarUrl);
};

export const makeStringSmall = (str) => {
  return str?.toLowerCase().replace(/\s/g, '');
};

export const freeLearnerCourseDashboardContent = [
  {
    id: 1,
    title: (course) => 'Unlocking Success: The Journey Begins!',
    para: 'This video will help you take a quick dive into your journey at AlmaBetter.',
    person: (course) => {
      return {
        name: 'Shivam Datta',
        designation: 'Co-founder & CEO, AlmaBetter',
      };
    },
    image: () => 'https://img.youtube.com/vi/tAQGFuXoi_0/0.jpg',
  },
  {
    id: 2,
    title: (course) =>
      course === COURSE_TYPE?.DATA_SCIENCE
        ? 'Cracking the Code: Inside the Certification in Full Stack Data Science and AI Curriculum!'
        : 'Cracking the Code: Inside the Certification in Full Stack Web Development Curriculum!',
    para: 'Learn about our pay after placement model along with curriculum tour',
    person: (course) => {
      if (course === COURSE_TYPE?.DATA_SCIENCE) {
        return {
          name: 'Alok Anand',
          designation: 'Co-founder & COO, AlmaBetter',
        };
      }
      return {
        name: 'Arshyan Ahsan',
        designation: 'Co-founder & CPSO, AlmaBetter',
      };
    },
    image: (course) =>
      course === COURSE_TYPE?.DATA_SCIENCE
        ? 'https://img.youtube.com/vi/JE00c5wUTGI/0.jpg'
        : 'https://img.youtube.com/vi/J1DhOLThsG8/0.jpg',
  },
  {
    id: 3,
    title: (course) => 'Career Success: Landing Top Jobs!',
    para: 'Learn about career outcomes and how we are going to get you placed in one of the leading tech companies.',
    person: (course) => {
      return {
        name: 'Ravi Kumar Gupta',
        designation: 'Co-founder & CBO, AlmaBetter',
      };
    },
    image: (course) =>
      course === COURSE_TYPE?.DATA_SCIENCE
        ? 'https://img.youtube.com/vi/bk1C7drPKnw/0.jpg'
        : 'https://img.youtube.com/vi/jQJWhHNE9D0/0.jpg',
  },
];

export function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export const generateWhatsAppShareUrl = (eventDetails) => {
  const shareUrl = `https://wa.me/?text=${encodeURIComponent(eventDetails)}`;
  return shareUrl;
};

export function generateMailtoLink(email = '', subject = '', body = '') {
  const encodedEmail = encodeURIComponent(email);
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);
  return `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`;
}
