import { API } from 'aws-amplify';
import { LEARN_SUBMISSION_API_ENDPOINT_NAME } from '../aws-exports';
import { returnCourseApiName } from './getcourse';

export const getProjects = async (course) => {
  const courseName = returnCourseApiName(course);
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/project/${courseName}`);
};

export const submitProject = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/project/submit`, {
    body: data,
  });
};

export const reEvalutateProject = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/project/reevaluation-requested`, {
    body: data,
  });
};

export const evaluateProject = async (data) => {
  return await API.put(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/project/evaluate-project/${data?.id}`
  );
};
