import { createSlice } from '@reduxjs/toolkit';
import { getAllCompanies } from '../services/userApiService';

const initialState = {
  companies: [],
};

const companySlice = createSlice({
  name: 'companyReducer',
  initialState,
  reducers: {
    setAllCompanies: (state, action) => {
      state.companies = action.payload;
    },
  },
});

export const { setAllCompanies } = companySlice.actions;

export function fetchCompanies() {
  return async (dispatch) => {
    const response = await getAllCompanies();
    dispatch(setAllCompanies(response));
  };
}

export default companySlice.reducer;
