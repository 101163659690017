import { API } from 'aws-amplify';
import {
  LEARN_SUBMISSION_API_ENDPOINT_NAME,
  LEADSQUARED_API_GATEWAY_ENDPOINT_NAME,
} from '../aws-exports';
import { returnCourseApiName } from './getcourse';

export const getFreeUserCurrentStage = async (course) => {
  const courseName = returnCourseApiName(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/user/getting-started-stage?courseType=${courseName}`
  );
};

export const postFreeUserCurrentStage = async (body) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/user/getting-started-stage`, {
    body,
  });
};

export const FUDVisitTracker = async () => {
  return await API.post(LEADSQUARED_API_GATEWAY_ENDPOINT_NAME, `/api/fud-visit-capture`, {
    body: {},
  });
};
