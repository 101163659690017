import _ from 'lodash';
import { returnCourseApiName } from './services/getcourse';
export const getDomain = () => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  return domain;
};

const DOMAIN = getDomain() || 'http://localhost:3000';

export const ALMABETTER_HOME_LINKS = {
  ROOT: 'https://www.almabetter.com',
  get TERM_AND_CONDITIONS() {
    return `${this.ROOT}/terms-of-use`;
  },
  get PRIVACY() {
    return `${this.ROOT}/privacy-policy`;
  },
  get ABOUT_US() {
    return `${this.ROOT}/about-us`;
  },
  get COMMUNITY() {
    return `${this.ROOT}/community`;
  },
};

export const THINKIFIC_ALMABETTER_LINKS = {
  ROOT: 'https://learn.almabetter.com',
  get DATA_SCIENCE_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-live-onboarding/multimedia/27676716-almax-pre-registration-form`;
  },
  get DATA_SCIENCE_PRO_FLEX_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-flex-onboarding/multimedia/27488097-almax-pre-registration-form`;
  },
  get WEB3_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/web-development-pro-program-onboarding/multimedia/33533152-almax-pre-registration-form`;
  },
  get ENROLLMENTS_PAGE() {
    return `${this.ROOT}/enrollments`;
  },
  get SSO_LOGOUT_URL() {
    return `${this.ROOT}/users/sign_out`;
  },
};

export const IMAGE_FILE_SIZE_MAX = 1449616;

export const THINKIFIC_CONSTANTS = {
  SSO_LOGIN: {
    URL: 'https://almabetter.thinkific.com/api/sso/v2/sso/jwt',
    ERROR_URL: `${DOMAIN}/thinkific_login_error`,
  },
};

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_TO: 'REDIRECT_TO',
  LOGIN_PROCESS_STAGE: 'LOGIN_PROCESS_STAGE',
  LOGOUT_PROCESS_STAGE: 'LOGOUT_PROCESS_STAGE',
  COURSE_INTERESTS: 'COURSE_INTERESTS',
  REFERRAL_CODE: 'REFERRAL_CODE',
  REFERRAL_GOAL: 'REFERRAL_GOAL',
  REFERRAL_PLAN: 'REFERRAL_PLAN',
  LINKEDIN_SHARE: 'LINKEDIN_SHARE',
  REFERRAL_SOURCE: 'REFERRAL_SOURCE',
  JOB_DASHBOARD_VIEWED: 'JOB_DASHBOARD_VIEWED',
  GLOBAL_SETTINGS: 'GLOBAL_SETTINGS',
  CAMPAIGN_PARAMS: 'CAMPAIGN_PARAMS',
};

export const LOGIN_LOGOUT_PROCESS_STAGE_VALUES = {
  COGNITO_AUTH_SUCCESSFUL: 'COGNITO_AUTH_SUCCESSFUL',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
};

export const ASSIGNMENT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/submissionPart1.mp4';
export const CAPSTONE_PROJECT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/capstoneProjectVideo.mp4';

export const COURSES_OFFERED = ['data-science', 'wev-dev'];

export const RAZORPAY = {
  SCRIPT_SRC: 'https://checkout.razorpay.com/v1/checkout.js',
  KEY_ID: process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_test_gVz50FqbpOF707',
  CURRENCY: 'INR',
};

export const ALMABETTER_LOGO_URL =
  'https://ab-public-bucket-prod.s3.ap-south-1.amazonaws.com/razorpay_icon.png';

export const PLAN_TYPE = {
  PRO_FLEX: 'pro_flex',
  PRO_LIVE: 'pro_live',
  PRO_LEGACY: 'pro_legacy',
  PRO_UPGRADE: 'pro_upgrade',
  NEW_USER: 'new_user',
  PREMIUM: 'premium',
  CORPORATE: 'corporate_training',
  MASTER: 'masters',
};

export const PLAN_TYPE_NAMES_MAPPING = {
  [PLAN_TYPE.PRO_FLEX]: 'Pro Flex',
  [PLAN_TYPE.PRO_LIVE]: 'Pro Live',
  [PLAN_TYPE.PRO_LEGACY]: 'Pro User',
  [PLAN_TYPE.NEW_USER]: 'New User',
  [PLAN_TYPE.PREMIUM]: 'Premium',
  [PLAN_TYPE.MASTER]: 'Masters',
};

export const COURSE_TYPE = {
  DATA_SCIENCE: 'data-science',
  WEB_DEV: 'web-dev',
  DATA_ENGINEERING: 'data-engineering',
  DS_BOOTCAMP: 'ds-bootcamp',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
  WEB3: 'web3',
};

export const COURSE_TYPE_NAMES_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Certification in Full Stack Data Science and AI',
  [COURSE_TYPE.WEB_DEV]: 'Certification in Full Stack Web Development',
  [COURSE_TYPE.DS_BOOTCAMP]: 'Data Science Ninja Bootcamp',
  [COURSE_TYPE.DATA_ENGINEERING]: 'Full Stack Data Engineering',
  [COURSE_TYPE.MASTERS_DS_AS]: 'Masters in CS : Data Science and Artificial Intelligence',
  [COURSE_TYPE.MASTERS_CS]: 'Masters in Computer Science : Software Engineering',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma in Computer Science',
};

export const BOOKING_STAGE = {
  CREATED: 'created',
  TIME_SLOTS_ADDED: 'timeSlotsAdded',
  COACH_ASSIGNED: 'coachAssigned',
  SESSION_CONDUCTED: 'sessionConducted',
  FEEDBACK_SUBMITTED: 'feedbackSubmitted',
  ADMIN_ON_HOLD: 'adminOnHold',
  ADMIN_APPROVED: 'adminApproved',
  CANCELLED: 'cancelled',
};

export const BOOKING_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DEFAULTED: 'defaulted',
};

export const USER_CREDITS_DEDUCTION_AMOUNT = 15;
export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://strapiv2.almagrow.com/api/';

export const COMMUNITY = 'https://community.almabetter.com/';
export const DATE_FORMAT_1 = 'Do MMM YYYY, hh:mm A';
export const DATE_ONLY_FROMAT_1 = 'Do MMM YYYY';
export const DATE_ONLY_FROMAT_2 = 'dddd, DD MMM YYYY';

export const MENTORSHIP_RESOURCE_LINK =
  'https://almabetter.notion.site/Mentorship-65a81629919b4ee1b26efaa393d6851c';

export const FRESHDESK_SUPPORT_URL =
  'https://almabetter.myfreshworks.com/login/auth/1662641230821?client_id=451980303908285122&redirect_uri=https%3A%2F%2Falmabetter-support.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dsupport.almabetter.com';

export const PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  EDUCATION: 'EDUCATION',
  WORK_EXPERIENCE: 'WORK_EXPERIENCE',
  SOCIAL: 'SOCIAL',
  SKILLS: 'SKILLS',
  GOAL: 'GOAL',
};

export const UG_PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  TWELTH_EDUCATION: '12_EDUCATION',
  GRADUATION_EDUCATION: 'GRADUATION_EDUCATION',
  GOAL: 'GOAL',
};

const COURSE_PATHS = {
  FULL_STACK_DATA_SCIENCE: 'full-stack-data-science',
  FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3: 'full-stack-web-development-with-web3',
  DS_BOOTCAMP: 'ds-bootcamp',
  ALMAX_THE_LAST_MILE: 'almax-the-last-mile-new',
  DATA_ENGINEERING: 'full-stack-data-engineering',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
};
export const COURSE_TYPE_COURSE_PATH_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: COURSE_PATHS.FULL_STACK_DATA_SCIENCE,
  [COURSE_TYPE.WEB_DEV]: COURSE_PATHS.FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3,
  [COURSE_TYPE.DS_BOOTCAMP]: COURSE_PATHS.DS_BOOTCAMP,
  [COURSE_TYPE.DATA_ENGINEERING]: COURSE_PATHS.DATA_ENGINEERING,
  [COURSE_TYPE.MASTERS_DS_AS]: COURSE_PATHS.MASTERS_DS_AS,
  [COURSE_TYPE.MASTERS_CS]: COURSE_PATHS.MASTERS_CS,
  [COURSE_TYPE.UG_DIPLOMA_CS]: COURSE_PATHS.UG_DIPLOMA_CS,
};

export const COURSE_PATH_COURSE_TYPE_MAPPING = _.invert(COURSE_TYPE_COURSE_PATH_MAPPING);
export const getCriteriaForGivenCoursePath = (coursePath) => {
  if (COURSE_PATH_COURSE_TYPE_MAPPING[coursePath]) {
    const courseType = COURSE_PATH_COURSE_TYPE_MAPPING[coursePath];
    return { courseType };
  } else if (coursePath === COURSE_PATHS.ALMAX_THE_LAST_MILE) {
    return { courseType: COURSE_TYPE.DATA_SCIENCE, almax: true };
  }
  return {};
};
export const WEB_SOCKETS_ENDPOINT =
  process.env.REACT_APP_WEB_SOCKETS_ENDPOINT ||
  'wss://7nq4wvs58h.execute-api.ap-south-1.amazonaws.com/dev';

export const DISCORD_AUTH_CLIENT_ID =
  process.env.REACT_APP_DISCORD_AUTH_CLIENT_ID || '1098890425391132684';

export const COMMUNITY_EVENT_TYPES = {
  PAYMENT: 'payment',
  PLACEMENT: 'placement',
  ROUND_ASSIGNED: 'round-assigned',
  COURSE_COMPLETION: 'course-completion',
  PROJECT_COMPLETED: 'project-completed',
  ASSESSMENT_COMPLETED: 'assessment-completed',
  MODULE_COMPLETED: 'module-completed',
};

export const COURSE_SELECTOR = {
  certification: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.DATA_SCIENCE],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.DATA_SCIENCE,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.WEB_DEV],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.WEB_DEV,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
  ],
  masters: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_DS_AS],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_DS_AS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_CS],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_CS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
  ],
  // ugCourses: [
  //   {
  //     name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.UG_DIPLOMA_CS],
  //     value: COURSE_TYPE.UG_DIPLOMA_CS,
  //     planType: PLAN_TYPE.PRO_LIVE,
  //     courseType: COURSE_TYPE.UG_DIPLOMA_CS,
  //     duration: '12 months',
  //     type: 'Part-time',
  //     classType: 'Online live classes',
  //     accredited: {
  //       woolf: true,
  //       iitGuwahati: true,
  //     },
  //     for: '1st, 2nd & 3rd Year College Students',
  //     outcome: `Internship + Diploma + 5+LPA Job`,
  //   },
  // ],
};

export const ENROLLED_IN = {
  CERTIFICATION: 'certification',
  MASTERS: 'masters',
  UG_DIPLOMA: 'ugCourses',
};

export const COURSE_INTEREST_COURSE_TYPE = {
  dataScienceInterest: COURSE_TYPE.DATA_SCIENCE,
  softDevInterest: COURSE_TYPE.WEB_DEV,
  mastersDSInterest: COURSE_TYPE.MASTERS_DS_AS,
  mastersCSInterest: COURSE_TYPE.MASTERS_CS,
  ugdiplomaCSInterest: COURSE_TYPE.WEB_DEV,
};

export const getGoalAndPlanBasedOnInterest = {
  [COURSE_TYPE.DATA_SCIENCE]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.WEB_DEV]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.MASTERS_DS_AS]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.MASTERS_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.UG_DIPLOMA_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
};

export const LESSON_ACTIVITIES_IDS_FOR_REFINER = {
  Concept: 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Colab': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Notion': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  Video: '8aa794c0-4268-11ee-a2af-f9fae2984f65',
  Quiz: 'a89d84f0-426e-11ee-a2ca-9feca5aa8883',
  Code: '0a405710-4272-11ee-8ad9-99e125048ea3',
  Assignment: 'e4eca580-4272-11ee-aa24-fd4a05278182',
};

export const SUB_PLAN_TYPES = {
  PREMIUM_AB: 'premium_ab',
  ICONIC_IITG: 'iconic_iitg',
  ICONIC_WOOLF: 'iconic_woolf',
  GLOBAL_IITG_WOOLF: 'global_iitg_woolf',
};

export const SUB_PLAN_TYPES_MAPPING = {
  [SUB_PLAN_TYPES.PREMIUM_AB]: 'Premium',
  [SUB_PLAN_TYPES.ICONIC_IITG]: 'Iconic',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: 'Iconic',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: 'Global',
};

export const EMI_PLANS_FOR_SUB_PLAN_TYPES = {
  [SUB_PLAN_TYPES.ICONIC_IITG]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: '*Pay in easy EMIs starting at ₹ 5,555 per month',
};

export const COURSE_TYPE_COURSE_NAME_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Data Science',
  [COURSE_TYPE.WEB3]: 'Web Development',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma CS',
};
