import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import themeReducer from './themeSlice';
import userSubscriptionReducer from './userSubscriptionSlice';
import companyReducer from './companySlice';
import colorModeReducer from './colorModeSlice';
import learnReducer from './learnSlice';
import courseReducer from './courseSlice';
import questionLevelsReducer from './questionLevelsSlice';
import referAndEarnReducer from './referAndEarnSlice';
import problemReducer from './problemsSlice';
import navigationReducer from './navigationSlice';
import practiceReducer from './practiceSlice';
import projectReducer from './projectsSlice';
import learnTabReducer from './learnTabSlice';
import homeReducer from './homeSlice';
import carrerReducer from './carrerSlice';
import mastersReducer from './mastersSlice';
import paymentReducer from './paymentSlice';
import webSocketReducer from './webSocketSlice';
import ugReducer from './ugDiplomaSlice';
import bannerReducer from './bannerSlice';
import shareAndEarnReducer from './shareEarnSlice';
import jobBoardReducer from './jobBoardSlice';

const store = configureStore({
  reducer: {
    authReducer,
    themeReducer,
    userSubscriptionReducer,
    companyReducer,
    colorModeReducer,
    learnReducer,
    courseReducer,
    questionLevelsReducer,
    referAndEarnReducer,
    problemReducer,
    navigationReducer,
    practiceReducer,
    projectReducer,
    learnTabReducer,
    homeReducer,
    carrerReducer,
    mastersReducer,
    paymentReducer,
    webSocketReducer,
    ugReducer,
    bannerReducer,
    shareAndEarnReducer,
    jobBoardReducer,
  },
  devTools: process.env.NODE_ENV === 'development', // disables redux devtools in production
});

export default store;
