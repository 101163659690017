import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation, withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

function Layout({ children }) {
  const location = useLocation();
  useEffect(() => {
    ReactPixel.pageView();
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    const pathname = { pathname: location.pathname };
    window.gtag('event', 'page_visited', pathname);
  }, [location]);

  return <div className='min-h-screen'>{children}</div>;
}

export default withRouter(Layout);
