import { createSlice } from '@reduxjs/toolkit';
import { getAllThemes } from '../modules/new-mentorship/apiService';

const initialState = {
  themes: [],
  topics: [],
  subTopics: [],
};

const themeSlice = createSlice({
  name: 'themeReducer',
  initialState,
  reducers: {
    setThemes: (state, action) => {
      state.themes = action.payload;

      let Topics = [];
      action.payload.forEach(({ topics }) => Topics.push(...topics));
      state.topics = Topics;

      let SubTopics = [];
      Topics.forEach(({ subTopics }) => SubTopics.push(...subTopics));
      state.subTopics = SubTopics;
    },
  },
});

export const { setThemes } = themeSlice.actions;

export const themesSelector = (state) => state.themeReducer.themes;

export const topicsSelector = (state) => state.themeReducer.topics;

export const fetchAllThemes = () => async (dispatch, getState) => {
  const existingThemes = themesSelector(getState());
  if (!existingThemes?.length) {
    const themes = await getAllThemes();
    dispatch(setThemes(themes));
  }
};

export default themeSlice.reducer;
