import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { MODULES_PATH } from '../../../../route-constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSubscription } from '../../../../redux/userSubscriptionSlice';
import { getJWTfreshDesk } from '../../../../services/paymentApiService';

const AuthSSO = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const obj = new URLSearchParams(location.search);
  const nonce = obj.get('nonce');
  const state = obj.get('state');

  const currentLocation = window.location.href;
  const userSubscription = useSelector(
    (state) => state.userSubscriptionReducer.subscriptionDetails
  );

  async function checkIfLoggedIn() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        history.push(`${MODULES_PATH.AUTH.LOGIN}?redirectTo=${currentLocation}`);
      }
      dispatch(fetchUserSubscription());
    } catch (e) {
      console.error(e);
      history.push(`${MODULES_PATH.AUTH.LOGIN}?redirectTo=${currentLocation}`);
    }
  }

  const handleRedirect = async () => {
    try {
      const response = await getJWTfreshDesk(nonce);
      const { token } = response;
      window.location.replace(
        `https://almabetter.myfreshworks.com/sp/OIDC/498080744406824764/implicit?state=${state}&id_token=${token}`
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userSubscription) {
      if (!Object.keys(userSubscription || {})?.length) history.replace('/');
      else handleRedirect();
    } else {
      if (!nonce) {
        window.location.replace(
          'https://almabetter.myfreshworks.com/login/auth/1662641230821?client_id=451980303908285122&redirect_uri=https%3A%2F%2Falmabetter-support.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dsupport.almabetter.com'
        );
      } else checkIfLoggedIn();
    }
  }, [userSubscription]);

  return (
    <div
      className='grid place-items-center h-screen w-screen'
      style={{ backgroundColor: '#EBEFF3' }}
    >
      <span
        className='w-10/12 md:w-full text-center font-medium md:text-2xl'
        style={{ color: 'rgb(18, 52, 77)' }}
      >
        Redirecting to almabetter.support.com{' '}
        <span className='h-auto text-6xl mx-2 md:text-7xl tracking-wide animate-ping'>...</span>
      </span>
    </div>
  );
};

export default AuthSSO;
