import React, { useState } from 'react';
import bgImage from '../_assets/forgot_pass_bg.webp';
import { Auth } from 'aws-amplify';
import PasswordStrengthBar from 'react-password-strength-bar';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CircularProgress } from '@material-ui/core';
import { MODULES_PATH } from '../../../route-constants';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../../../utils';
import logo from '../../../static/img/almabetterLogo.png';

const errorCodes = {
  UserNotFoundException: 'User with this email not found',
};

const renderEmail = (email) => {
  let emailString = email.split('@');
  emailString = emailString[0];
  const subString = email.slice(2, emailString?.length - 2);
  const emailSubstring =
    emailString.replace(subString, '*'.repeat(emailString?.length - 4)) + '@' + email.split('@')[1];
  return emailSubstring;
};
const validateConfirmPassword = (pass, confirmPass) => {
  return pass === confirmPass;
};
function ConfirmPassword({ email, onPasswordSuccessfullyReset }) {
  const [error, setError] = useState({});
  const [form, setForm] = useState({});
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const renderedEmail = renderEmail(email);

  const handleInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const validatePassword = (score, feedback) => {
    if (form.password && score >= 0 && score <= 2) {
      setError({
        ...error,
        password: feedback.warning ? feedback.warning : 'Please select a better password',
      });
    } else setError({ ...error, password: '' });
  };

  const validate = () => {
    const temp = {};
    temp.code = form.code ? '' : 'This field is required';
    temp.password = form.password
      ? error.password
        ? error.password
        : ''
      : 'This field is required';
    temp.confirmPassword = form.confirmPassword
      ? validateConfirmPassword(form.password, form.confirmPassword)
        ? ''
        : 'Please make sure the confirm password is same as password'
      : 'This field is required';

    if (!Object.values(temp).every((value) => value === '')) setError(temp);
    else resetPassword();
  };

  const resetPassword = async () => {
    setResetPasswordLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, form.code, form.password);
      onPasswordSuccessfullyReset();
    } catch (err) {
      const message = err.message || 'Failed resetting password';
      setError({ message });
    } finally {
      setResetPasswordLoading(false);
    }
  };
  return (
    <>
      <div className='flex flex-col gap-2 font-medium'>
        <h3>We have sent a password reset code to {renderedEmail}</h3>
        <h3>Enter it below to reset your password</h3>
      </div>
      {error.message && (
        <span className='text-sm text-red-500 flex items-center gap-1'>
          <ErrorOutlineIcon /> {error.message}
        </span>
      )}
      <div className='flex flex-col'>
        <input
          className='border border-solid border-gray-300 rounded h-12 p-2'
          name='code'
          type='text'
          placeholder='Enter code'
          value={form.code}
          onChange={handleInput}
        />
        <span className='text-sm text-red-500 place-self-start text-left'>{error.code}</span>
      </div>
      <div className='flex flex-col'>
        <input
          name='password'
          className='border border-solid border-gray-300 rounded h-12 p-2'
          type='password'
          placeholder='Enter new password'
          value={form.password}
          onChange={handleInput}
        />
        <PasswordStrengthBar password={form.password} onChangeScore={validatePassword} />
        <span className='text-sm text-red-500 place-self-start text-left'>{error.password}</span>
      </div>
      <div className='flex flex-col'>
        <input
          name='confirmPassword'
          className='border border-solid border-gray-300 rounded h-12 p-2'
          type='password'
          placeholder='Confirm new password'
          value={form.confirmPassword}
          onChange={handleInput}
        />
        <span className='text-sm text-red-500 place-self-start'>{error.confirmPassword}</span>
      </div>
      <button
        className='px-4 py-3 bg-ab-secondary text-ab-tertiary font-medium rounded'
        onClick={validate}
        disabled={resetPasswordLoading}
      >
        {resetPasswordLoading ? (
          <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
        ) : (
          'Reset my password'
        )}
      </button>
    </>
  );
}

function PutEmail({ onEmailSuccessfullyInput }) {
  const [email, setEmail] = useState('');
  const [resetButtonLoading, setResetButtonLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const validateForm = () => {
    let errorMsg = '';
    if (!email) errorMsg = 'Email Field is required';
    if (!errorMsg && !validateEmail(email)) errorMsg = 'Email is not valid';
    if (errorMsg) {
      setErrorMsg(errorMsg);
      return errorMsg;
    }
  };

  const onResetButtonClicked = async () => {
    const errorMsg = validateForm();
    if (errorMsg) return;
    try {
      setResetButtonLoading(true);
      await Auth.forgotPassword(email);
      onEmailSuccessfullyInput(email);
    } catch (e) {
      let errorMsg = 'Error resetting email';
      if (e.code in errorCodes) {
        errorMsg = errorCodes[e.code];
      }
      setErrorMsg(errorMsg);
    } finally {
      setResetButtonLoading(false);
    }
  };

  return (
    <>
      <h1 className='font-semibold text-2xl'>Forgot your password?</h1>
      <h3 className='text-gray-500'>
        Enter your email below and we will send a message to reset your password
      </h3>
      <input
        className='border border-solid border-gray-300 rounded h-12 my-2 p-2'
        type='text'
        placeholder='Enter your email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {errorMsg && <span className='2xl:w-full text-sm text-red-500'>{errorMsg}</span>}
      <button
        className='px-4 py-3 bg-ab-secondary text-ab-tertiary font-medium rounded'
        onClick={onResetButtonClicked}
        disabled={resetButtonLoading}
      >
        {resetButtonLoading ? (
          <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
        ) : (
          'Reset my password'
        )}
      </button>
    </>
  );
}

export default function ResetPassword() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(1);
  const onPasswordSuccessfullyReset = () => {
    history.push(MODULES_PATH.AUTH.LOGIN);
  };

  return (
    <div className='h-screen max-h-screen overflow-hidden grid place-items-center'>
      <img className='h-full w-full absolute' src={bgImage} alt='Background' />
      <div className='bg-ab-tertiary w-full absolute top-0 left-0 z-20 flex items-center'>
        <img className='w-36 md:w-44 m-4' src={logo} alt='Logo' />
      </div>
      <div className='bg-ab-tertiary relative z-10 mx-4 md:mx-0 md:w-4/12 text-ab-primary text-center p-8 flex flex-col gap-6 rounded-lg'>
        {page === 1 && (
          <PutEmail
            onEmailSuccessfullyInput={(emailParam) => {
              setEmail(emailParam);
              setPage(2);
            }}
          />
        )}
        {page === 2 && (
          <ConfirmPassword
            onPasswordSuccessfullyReset={onPasswordSuccessfullyReset}
            email={email}
          />
        )}
      </div>
    </div>
  );
}
