import { API } from 'aws-amplify';
import {
  LEARN_SUBMISSION_API_ENDPOINT_NAME,
  USER_API_GATEWAY_ENDPOINT_NAME,
} from 'src/aws-exports';

export const getMastersApplicationStatus = async (course) => {
  return await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/masters-application/new-application-status?goalType=${course}`
  );
};

export const getMastersDegreeId = async (course) => {
  return API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/woolf/get-degree?courseType=${course}`);
};

export const initMastersApplication = (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/masters-application/init-application`, {
    body,
  });
};

export const completeMastersProfile = (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/masters-application/complete-profile`, {
    body,
  });
};

export const completeUniversityOnboarding = (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/masters-application/university-onboarded`, {
    body,
  });
};

export const submitMastersSOP = (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/masters-application/submit-sop`, {
    body,
  });
};

export const scholarshipTestData = (body) => {
  return API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/masters-application/assessments?courseType=${body}`
  );
};
