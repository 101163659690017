import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { authDiscordsUser } from 'src/services/userApiService';
import toastWrapper from 'src/components/Extras/toastMessages';
import { FaDiscord } from 'react-icons/fa';

function Discord() {
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState({
    showLoader: true,
    url: null,
  });

  const redirectUser = () => {
    const openedTab = state.url && window.open(state.url, '_blank');

    if (openedTab != null) {
      history.push('/');
    }
  };

  const authenticateUser = async () => {
    try {
      const params = new URLSearchParams(location?.search);
      const code = params.get('code');

      if (!code) {
        history.push('/');
        toastWrapper('warning', 'Something went wrong!', 'top-right');
        return;
      }
      const res = await authDiscordsUser({ code });

      if (res.message === 'Success') {
        localStorage.setItem('discord_invitation', res.redirectUrl);
        setState({
          showLoader: false,
          url: res.redirectUrl,
        });
      }
    } catch (err) {
      console.error('[DISCORD AUTH ERROR]', err);
      toastWrapper('warning', 'Something went wrong!', 'top-right');
      history.push('/');
    }
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  useEffect(redirectUser, [state]);

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      {!state.showLoader && (
        <div className='flex flex-col gap-y-2 items-center'>
          <FaDiscord size={32} color='#7289DA' />
          <p className='font-extrabold text-lg'>
            If you're not redirected soon, please{' '}
            <button className='text-blue-600 underline underline-offset-4' onClick={redirectUser}>
              click here
            </button>{' '}
            to open discord.
          </p>
        </div>
      )}
      {state.showLoader && <CircularProgress color='secondary' size={100} />}
    </div>
  );
}

export default Discord;
