import { API } from 'aws-amplify';
import { USER_API_GATEWAY_ENDPOINT_NAME } from '../aws-exports';

export const getAllJobs = async (body) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/job-board/fetch`, {
    body,
  });
};

export const getSavedJobs = async (body) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user-job-board/fetch`, {
    body,
  });
};

export const postSavedJob = async (jobId) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user-job-board/save`, {
    body: { jobId },
  });
};

export const removedSavedJob = async (jobId) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user-job-board/remove`, {
    body: { jobId },
  });
};

export const getSavedJobsCount = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user-job-board/total-counts`);
};

export const getJobParams = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/job-board/filter-params`);
};

export const getJobById = async (jobId) => {
  return await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/job-board/fetch/by-job-id?jobId=${jobId}`
  );
};

export const getJobsAddedCount = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/job-board/no-of-jobs-added`);
};
