import { API } from 'aws-amplify';
import { USER_API_GATEWAY_ENDPOINT_NAME } from '../aws-exports';

export const getReferralPrograms = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/referrer/programs`);
};

export const getShareAndEarnConfig = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/referrer/share-earn-conf`);
};

export const postReferralCode = async (body) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/referred/v2/push-referral-code`, {
    body,
  });
};

export const getReferrarInfo = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/referrer/info`);
};

export const getAllReferrals = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/referrer/referrals');
};
