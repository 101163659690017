import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showStripBanner: null,
};

const bannerSlice = createSlice({
  name: 'bannerReducer',
  initialState,
  reducers: {
    setShowStripBanner: (state, action) => {
      state.showStripBanner = action.payload;
    },
  },
});

export const { setShowStripBanner } = bannerSlice.actions;

export const getshowStripBanner = (state) => state.bannerReducer.showStripBanner;

export default bannerSlice.reducer;
