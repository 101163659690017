/* eslint-disable camelcase */
import _ from 'lodash';
import { COURSE_TYPE } from 'src/constants';

export const individualTrackProgress = (courseData, item = 'FOUNDATION_TRACK', trackModules) => {
  try {
    const modules = trackModules ?? courseData?.[item]?.learn_modules;
    const weightage = 100 / modules?.length / 100;
    let progress = 0;
    for (const module of modules) {
      progress += moduleProgress(module) * weightage;
    }
    return Math.round(progress ?? 0);
  } catch (error) {
    console.error(error);
  }
};

export const bootcampProgress = (courseData, name = 'FOUNDATION_TRACK') => {
  const trackProgress = individualTrackProgress(courseData, name);
  const clonedCourseData = _.cloneDeep(courseData);
  clonedCourseData.BASIC_INFO.progress = trackProgress;
  return clonedCourseData;
};

export const ugProgress = (courseData) => {
  try {
    const weightage = {
      foundation: 0.5,
      specialisation: 0.25,
      industry: 0.25,
    };

    const foundationTrackProgress = individualTrackProgress(courseData, 'FOUNDATION_TRACK') ?? 0;
    const industryTrackProgress = individualTrackProgress(courseData, 'INDUSTRY_TRACK') ?? 0;
    const specialisationProgress = individualTrackProgress(courseData, 'SPECIALISATION_TRACK') ?? 0;

    const courseProgress =
      weightage.foundation * foundationTrackProgress +
      weightage.industry * industryTrackProgress +
      weightage.specialisation * specialisationProgress;
    return Math.round(courseProgress);
  } catch (error) {
    console.error(error);
  }
};

export const mastersProgress = (courseData) => {
  try {
    let totalProgress = 0;
    const specialisationAccess = courseData?.FOUNDATION_TRACK?.progress >= 100;
    const foundationTrackProgress = individualTrackProgress(courseData, 'FOUNDATION_TRACK') ?? 0;
    const industryTrackProgress = individualTrackProgress(courseData, 'INDUSTRY_TRACK') ?? 0;
    const specialisationProgress = specialisationAccess
      ? individualTrackProgress(courseData, 'SPECIALISATION_TRACK') ?? 0
      : 0;

    const weightage = 100 / 3 / 100;

    totalProgress =
      weightage * foundationTrackProgress +
      weightage * industryTrackProgress +
      weightage * specialisationProgress;
    totalProgress = Math.round(totalProgress ?? 0);

    return {
      totalProgress,
      FOUNDATION_TRACK: foundationTrackProgress,
      INDUSTRY_TRACK: industryTrackProgress,
      SPECIALISATION_TRACK: specialisationProgress,
    };
  } catch (error) {
    console.error(error);
  }
};

export const moduleProgress = (item) => {
  if (!item) return 0;
  try {
    const isProjectPresent = item?.attributes?.project_categories?.data?.length > 0 ?? null;
    const { solvedLessonsCount, totalLessonsCount } = item?.attributes ?? {};
    const isModuleProjectCompleted = item?.attributes?.projectStatus ?? null;
    let progress = 0;
    if (isProjectPresent) {
      progress = (solvedLessonsCount / totalLessonsCount) * 90;
      if (isModuleProjectCompleted) {
        progress += 10;
      }
    } else {
      progress = (solvedLessonsCount / totalLessonsCount) * 100;
    }
    return Math.floor(progress) ?? 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export function convertToWeeksOrMonths(value, unit = 'weeks') {
  const minutesInHour = 60;
  const hoursInDay = 24;
  const daysInWeek = 7;
  const daysInMonth = 30;

  let conversionFactor;

  if (unit === 'weeks') {
    conversionFactor = minutesInHour * hoursInDay * daysInWeek;
  } else if (unit === 'months') {
    conversionFactor = minutesInHour * hoursInDay * daysInMonth;
  }
  const result = (value ?? 0) / conversionFactor;
  return parseInt(result);
}

export const lessonContentMapper = (lesson) => {
  const lessonActivities = [];
  if (
    !_.isEmpty(lesson?.attributes?.gistUrl) ||
    !_.isEmpty(lesson?.attributes?.notion_id) ||
    !_.isEmpty(lesson?.attributes?.content)
  ) {
    lessonActivities.push('Concept');
  }
  if (
    !_.isEmpty(lesson?.attributes?.video_url) ||
    !_.isEmpty(lesson?.attributes?.cdnVideoUrl) ||
    !_.isEmpty(lesson?.attributes?.videoUrl)
  ) {
    lessonActivities.push('Video');
  }
  if (lesson?.attributes?.coding_assessment?.data) {
    lessonActivities.push('Code');
  }
  if (lesson?.attributes?.learn_assessment?.data) {
    lessonActivities.push('Quiz');
  }
  if (lesson?.attributes?.learn_assignment?.data) {
    lessonActivities.push('Assignment');
  }
  if (lesson?.attributes?.peer_review_publication) {
    lessonActivities.push('Peer');
  }
  return lessonActivities;
};

export const moduleProgressData = (courseData, moduleProgress) => {
  const data = _.cloneDeep(courseData);
  if (data?.courseId !== moduleProgress?.courseId) return courseData;

  const parsedKeys = Object.keys(data).filter((item) => {
    if (item !== 'BASIC_INFO' && item !== 'courseId') {
      return data[item];
    }
    return null;
  });

  const parsedData = parsedKeys.map((item) => data[item]);

  const tracksData = parsedData?.map((item) => {
    item.learn_modules?.forEach((module) => {
      if (module.id === moduleProgress?.moduleId) {
        module.attributes.progress = moduleProgress?.progress;
      }
    });
    return item;
  });
  const obj = {
    ...courseData,
  };
  tracksData?.forEach((item) => {
    const type = item?.trackType;
    if (type) {
      const trackType = type.includes('foundation')
        ? 'FOUNDATION_TRACK'
        : type.includes('industry')
        ? 'INDUSTRY_TRACK'
        : 'SPECIALISATION_TRACK';

      obj[trackType] = {
        ...item,
      };
    }
  });
  return obj;
};

export const trackProgressData = (courseData, trackProgress) => {
  try {
    const data = _.cloneDeep(courseData);
    if (data?.courseId !== trackProgress?.courseId) return courseData;

    const parsedKeys = Object.keys(data).filter((item) => {
      if (item !== 'BASIC_INFO' && item !== 'courseId') {
        return data[item];
      }
      return null;
    });

    const parsedData = parsedKeys.map((item) => data[item]);
    const tracksData = parsedData?.map((item) => {
      if (trackProgress?.tracks) {
        trackProgress?.tracks?.forEach((track) => {
          if (item.id === track?.trackId) {
            item.progress = track?.progress;
          }
        });
      }

      return item;
    });
    const obj = {
      ...courseData,
    };
    tracksData?.forEach((item) => {
      const type = item?.trackType;
      if (type) {
        const trackType = type.includes('foundation')
          ? 'FOUNDATION_TRACK'
          : type.includes('industry')
          ? 'INDUSTRY_TRACK'
          : 'SPECIALISATION_TRACK';

        obj[trackType] = {
          ...item,
        };
      }
    });
    return obj;
  } catch (error) {
    console.error(error);
  }
};

export const courseProgressData = (courseData, courseProgress) => {
  const data = _.cloneDeep(courseData);
  if (data?.courseId !== courseProgress?.courseId) return courseData;
  const obj = {
    ...data,
    BASIC_INFO: {
      ...data.BASIC_INFO,
      progress: courseProgress?.progress ?? 0,
    },
  };
  return obj;
};

export const projectProgressData = (courseData, projectProgress) => {
  const data = _.cloneDeep(courseData);
  if (data?.courseId !== projectProgress?.courseId) return courseData;

  const parsedKeys = Object.keys(data).filter((item) => {
    if (item !== 'BASIC_INFO' && item !== 'courseId') {
      return data[item];
    }
    return null;
  });
  const parsedData = parsedKeys.map((item) => data[item]);
  const tracksData = parsedData?.map((item) => {
    item.learn_modules?.forEach((module) => {
      if (module.id === projectProgress?.moduleId) {
        module.attributes.projectStatus = projectProgress?.projectStatus === 'completed';
      }
    });
    return item;
  });
  const obj = {
    ...courseData,
  };
  tracksData?.forEach((item) => {
    const type = item?.trackType;
    if (type) {
      const trackType = type.includes('foundation')
        ? 'FOUNDATION_TRACK'
        : type.includes('industry')
        ? 'INDUSTRY_TRACK'
        : 'SPECIALISATION_TRACK';

      obj[trackType] = {
        ...item,
      };
    }
  });
  return obj;
};

export const lessonCompletedData = (courseData, lessonData) => {
  const data = _.cloneDeep(courseData);
  if (data?.courseId !== lessonData?.courseId) return courseData;
  const parsedKeys = Object.keys(data).filter((item) => {
    if (item !== 'BASIC_INFO' && item !== 'courseId') {
      return data[item];
    }
    return null;
  });
  const parsedData = parsedKeys.map((item) => data[item]);
  const tracksData = parsedData?.map((item) => {
    lessonData?.trackIds?.forEach((trackId) => {
      if (item.id === trackId) {
        item.learn_modules?.forEach((module) => {
          module.attributes.learn_chapters.data.forEach((chapter) => {
            if (chapter?.id === lessonData?.chapterId) {
              chapter.attributes.learn_lessons.data.forEach((lesson) => {
                if (lesson?.id === lessonData?.lessonId) {
                  lesson.attributes.solvedStatus =
                    lessonData?.status === 'completed' ? 'complete' : 'in-progress';
                }
              });
            }
          });
        });
      }
    });

    return item;
  });
  const obj = {
    ...courseData,
  };
  tracksData?.forEach((item) => {
    const type = item?.trackType;
    if (type) {
      const trackType = type.includes('foundation')
        ? 'FOUNDATION_TRACK'
        : type.includes('industry')
        ? 'INDUSTRY_TRACK'
        : 'SPECIALISATION_TRACK';

      obj[trackType] = {
        ...item,
      };
    }
  });
  return obj;
};

export const returnLastLesson = (courseData, lastLesson) => {
  try {
    const { trackId, moduleId, chapterId, lessonId } = lastLesson;
    const lastTrack = Object.values(courseData)?.find((i) => i?.id === parseInt(trackId));
    const module = lastTrack?.learn_modules?.find((i) => i?.id === parseInt(moduleId)) ?? null;
    const moduleIndex =
      lastTrack?.learn_modules?.findIndex((i) => i?.id === parseInt(moduleId)) ?? 0;
    const chapter =
      module?.attributes?.learn_chapters?.data?.find((i) => i?.id === parseInt(chapterId)) ?? null;
    const chapterIndex =
      module?.attributes?.learn_chapters?.data?.findIndex((i) => i?.id === parseInt(chapterId)) ??
      0;
    const lesson = chapter?.attributes?.learn_lessons?.data?.find(
      (i) => i?.id === parseInt(lessonId)
    );
    const lastTrackType = lastTrack?.trackType ?? '';
    const convertedName = lastTrackType?.includes('specialisation')
      ? 'SPECIALISATION_TRACK'
      : lastTrackType?.includes('foundation')
      ? 'FOUNDATION_TRACK'
      : lastTrackType?.includes('industry')
      ? 'INDUSTRY_TRACK'
      : 'SPECIALISATION_TRACK' ?? '';

    return {
      lastChapterLesson: lesson?.id,
      openChapter: chapter
        ? {
            [moduleIndex]: {
              [chapterIndex]: true,
            },
          }
        : null,
      currTab: convertedName,
      lastTrack,
    };
  } catch (error) {
    console.error(error);
    return {
      lastChapterLesson: null,
      openChapter: null,
      currTab: null,
      lastTrack: null,
    };
  }
};

export const courseTag = (course, masters) => {
  if (course === COURSE_TYPE.DATA_SCIENCE && !masters) {
    return { months: 6, modules: 6 };
  } else if (course === COURSE_TYPE.DATA_SCIENCE && masters) {
    return { months: 15, modules: 13 };
  } else if (course === COURSE_TYPE.WEB_DEV && !masters) {
    return {
      months: 6,
      modules: 5,
    };
  } else if (course === COURSE_TYPE.WEB_DEV && masters) {
    return {
      months: 15,
      modules: 13,
    };
  } else if (course === COURSE_TYPE.MASTERS_CS) {
    return {
      months: 15,
      modules: 13,
    };
  } else if (course === COURSE_TYPE.MASTERS_DS_AS) {
    return {
      months: 15,
      modules: 13,
    };
  } else if (course === COURSE_TYPE.UG_DIPLOMA_CS) {
    return {
      months: 12,
      modules: 9,
    };
  }
};

export const moduleCardInfo = (item) => {
  if (!item) return 0;
  try {
    const isProjectPresent = item?.attributes?.project_categories?.data?.length > 0 ?? null;
    const strings = [];
    if (isProjectPresent) {
      strings.push(
        '90% progress equally on completing all lessons',
        '10% progress on completing the capstone project'
      );
    } else {
      strings.push('100% progress on completing all lessons');
    }
    return strings;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const gradeInfo = (item) => {
  try {
    const isProjectPresent = item?.attributes?.project_categories?.data?.length > 0 ?? null;
    const isGradePresent = item?.attributes?.learn_chapters?.data?.some((chapter) =>
      chapter?.attributes?.learn_lessons?.data?.some((lesson) => {
        return lesson?.attributes?.learn_assessment?.data?.attributes?.gradeWeight;
      })
    );
    const strings = [];
    if (isGradePresent) {
      strings.push('Mid/End Course Quiz Assessment');
    }
    if (isProjectPresent) {
      strings.push('Capstone Project');
    }
    return strings;
  } catch (error) {
    console.error(error);
  }
};
