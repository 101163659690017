import { createSlice } from '@reduxjs/toolkit';
import { returnActualCourseNameForApi } from '../services/getcourse';
import { getProblems } from '../services/practiceApiService';

const initialState = {
  optionTab: 0,
  defaultCodingLanguage: 'javascript',
  currentSelectedProblem: 0,
  currentSelectedCodingLanguage: 'javascript',
  code: {
    python: '',
    javascript: '',
    html: '',
    css: '',
    react: '',
    java: '',
  },
  headerIcon: {
    reset: false,
    settings: false,
    fullscreen: false,
  },
  tableData: {
    data: [],
    totalCount: 0,
    solvedCount: 0,
    solvedProblems: [],
    unsolvedProblems: [],
  },
  problemsList: {
    data: [],
    solvedCount: 0,
  },
  updateSubmission: false,
};

const problemDetailSlice = createSlice({
  name: 'problemDetailReducer',
  initialState,
  reducers: {
    selectTab: (state, action) => {
      state.optionTab = action.payload;
    },
    setUpdateSubmissions: (state, action) => {
      state.updateSubmission = action.payload;
    },
    selectDefaultCodingLanguage: (state, action) => {
      state.defaultCodingLanguage = action.payload.language;
    },
    selectedCodingLanguage: (state, action) => {
      state.currentSelectedCodingLanguage = action.payload;
    },
    resetTab: (state, action) => {
      state.optionTab = 0;
    },
    setCurrentSelectedProblem: (state, action) => {
      state.currentSelectedProblem = action.payload;
    },
    updateCode: (state, action) => {
      state.code[state.currentSelectedCodingLanguage] = action.payload;
    },
    resetCode: (state, action) => {
      state.code = {
        python: '',
        javascript: '',
        html: '',
        css: '',
        react: '',
        java: '',
      };
    },
    updateHeaderIcon: (state, action) => {
      state.headerIcon[action.payload.icon] = action.payload.value;
    },
    setTableData: (state, action) => {
      const compare = (a, b) => {
        if (!a.attributes.isLocked && b.attributes.isLocked) return -1;
        return 0;
      };

      const dataArray = action?.payload?.data;
      dataArray && dataArray.sort(compare);

      const unsolvedProblems = dataArray.filter(
        (problem) => problem.attributes.solvedStatus === 'unsolved'
      );
      const solvedProblems = dataArray.filter(
        (problem) => problem.attributes.solvedStatus === 'solved'
      );
      state.tableData.solvedProblems = solvedProblems;
      state.tableData.unsolvedProblems = unsolvedProblems;
      state.tableData.data = dataArray;
      state.tableData.solvedCount = action.payload.solved ?? action.payload.solvedCount;
    },
    setProblemsList: (state, action) => {
      state.problemsList.data = action.payload?.data;
      state.problemsList.solvedCount = action.payload?.solved ?? action.payload?.solvedCount;
    },
  },
});

export function fetchProblems() {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const data = await getProblems(modifiedCourse);
      dispatch(setTableData(data));
      dispatch(setProblemsList(data));
    } catch (error) {
      console.error(error);
    }
  };
}

export const {
  currentOptionTab,
  selectDefaultCodingLanguage,
  selectTab,
  selectedCodingLanguage,
  resetTab,
  setCurrentSelectedProblem,
  updateCode,
  updateHeaderIcon,
  setUpdateSubmissions,
  setTableData,
  setProblemsList,
  resetCode,
} = problemDetailSlice.actions;

export default problemDetailSlice.reducer;
