import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getMentorList } from 'src/modules/new-mentorship/apiService';
import { returnCourseApiName } from 'src/services/getcourse';
import { getEgligibilityForExtension } from 'src/services/userApiService';
import { chapterOrderSet } from '../modules/course-lms-v2/components/shared/utils';
import {
  getCarrerContent,
  getCoachesChampions,
  getEgligibilityCriteria,
  getFreeUserCarrer,
} from '../services/carrerTabApiService';
import { returnActualCourseNameForApi } from '../services/getcourse';

const carrerSlice = createSlice({
  name: 'carrerReducer',
  initialState: {
    almaXCoach: null,
    egligibilityCriteria: null,
    content: null,
    freeUser: null,
    almaxConfirmation: null,
    mentors: null,
    egligibleForExtension: {},
  },
  reducers: {
    setMentors: (state, action) => {
      state.mentors = action.payload;
    },
    setAlmaXCoach: (state, action) => {
      state.almaXCoach = action.payload;
    },
    setEgligibilityCriteria: (state, action) => {
      state.egligibilityCriteria = action.payload;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setFreeUser: (state, action) => {
      state.freeUser = action.payload;
    },
    resetAllCarrerData: (state, action) => {
      state.almaXCoach = null;
      state.egligibilityCriteria = null;
      state.content = null;
      state.freeUser = null;
    },
    setCheckForExtension: (state, action) => {
      state.egligibleForExtension = action.payload;
    },
  },
});

export const {
  setAlmaXCoach,
  setEgligibilityCriteria,
  setContent,
  setFreeUser,
  resetAllCarrerData,
  setAlmaxConfirmation,
  setMentors,
  setCheckForExtension,
} = carrerSlice.actions;

export const getAllMentors = (state) => state.carrerReducer.mentors;

export const egligibleForExtension = (state) => state.carrerReducer.egligibleForExtension;

export const fetchExtensionData = (course) => {
  return async (dispatch) => {
    try {
      const res = await getEgligibilityForExtension();
      dispatch(setCheckForExtension(res));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchAlmaXCoaches = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const result = await getCoachesChampions(modifiedCourse);
      dispatch(setAlmaXCoach(result));
    } catch (error) {
      console.error(error);
    }
  };
};

const careerModuleMapper = (data) => {
  try {
    const modulesData = [...data?.moduleCriterias];
    const moduleOrder = data?.moduleOrder ?? '';
    const moduleSlugs = moduleOrder.split('\n');
    const sortedData = [...modulesData].sort((a, b) => {
      const slugA = a?.moduleData?.data?.attributes?.slug ?? '';
      const slugB = b?.moduleData?.data?.attributes?.slug ?? '';
      const indexA = moduleSlugs.indexOf(slugA);
      const indexB = moduleSlugs.indexOf(slugB);
      return indexA - indexB;
    });
    data.moduleCriterias = sortedData;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchEgligibilityCriteria = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const result = await getEgligibilityCriteria(modifiedCourse);
      const cloneResult = _.cloneDeep(result);
      const mapped = careerModuleMapper(cloneResult);
      dispatch(setEgligibilityCriteria(mapped));
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchCarrerContent = (courseSelected) => {
  return async (dispatch, getState) => {
    try {
      const apiCourseName = returnActualCourseNameForApi(courseSelected);
      const { almaxContent } = await getCarrerContent(apiCourseName);
      const courseData = almaxContent?.attributes?.learn_tracks?.data?.[0]?.attributes;
      const finalData = chapterOrderSet(courseData);
      dispatch(setContent(finalData));
    } catch (error) {
      console.error(error, 'error');
    }
  };
};

export const fetchFreeUserCarrer = (course) => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const planType = (actualCourseSelected ?? courseSelected).includes('masters')
        ? 'masters'
        : 'pro_live';
      const result = await getFreeUserCarrer({
        course: modifiedCourse,
        plan: planType,
      });
      dispatch(setFreeUser(result));
    } catch (error) {
      console.error(error, 'error');
    }
  };
};

export const fetchMentorsList = () => {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const course = returnCourseApiName(actualCourseSelected || courseSelected);
      const modifiedCourse = returnActualCourseNameForApi(course);
      const response = await getMentorList(modifiedCourse);
      dispatch(setMentors(response));
    } catch (error) {
      console.error('error fetching data', error);
    }
  };
};

export default carrerSlice.reducer;
