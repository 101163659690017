import { Route, Switch } from 'react-router-dom';
import Login from './login/page';
import { MODULES_PATH } from '../../route-constants';
import SignUp from './signup/page';
import ResetPassword from './forgot_your_password/page';
import Logout from './logout/page';
import LoginSuccessful from './login_successful/page';
import FreshdeskSSO from './sso_login/freshdesk/page';
import ThinkificSSO from './sso_login/thinkific/page';
import Discord from './discord/page';

export default function AuthRouter() {
  return (
    <div id='login'>
      <Switch>
        <Route exact path={MODULES_PATH.AUTH.LOGIN} component={Login} />
        <Route exact path={MODULES_PATH.AUTH.FRESHDESK_SSO_LOGIN} component={FreshdeskSSO} />
        <Route exact path={MODULES_PATH.AUTH.THINKIFIC_SSO_LOGIN} component={ThinkificSSO} />
        <Route exact path={MODULES_PATH.AUTH.LOGIN_SUCCESSFUL} component={LoginSuccessful} />
        <Route exact path={MODULES_PATH.AUTH.SIGN_UP} component={SignUp} />
        <Route exact path={MODULES_PATH.AUTH.LOGOUT} component={Logout} />
        <Route exact path={MODULES_PATH.AUTH.LOGOUT_SUCCESSFUL} component={Logout} />
        <Route exact path={MODULES_PATH.AUTH.FORGOT_YOUR_PASSWORD} component={ResetPassword} />
        <Route exact path={MODULES_PATH.AUTH.DISCORD} component={Discord} />
      </Switch>
    </div>
  );
}
