import { createSlice } from '@reduxjs/toolkit';
import {
  getAllJobs,
  getJobParams,
  getSavedJobs,
  getSavedJobsCount,
} from '../services/jobBoard.service';

const initialState = {
  jobs: {},
  savedJobs: {},
  loading: false,
  currentPage: 1,
  savedJobsCount: null,
  currentSavedJobsPage: 1,
  filterParams: {},
  searchRole: '',
  filters: {
    workExperience: {},
    location: [],
    disableFilter: true,
    domain: '',
    employmentType: '',
    workString: '',
    jobLocation: '',
    skills: [],
    companies: [],
  },
};

const jobBoardSlice = createSlice({
  name: 'jobBoardReducer',
  initialState,
  reducers: {
    updateJobs: (state, action) => {
      state.jobs = action.payload;
    },
    updateSavedJobs: (state, action) => {
      state.savedJobs = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateJobLocation: (state, action) => {
      state.filters.jobLocation = action.payload;
    },
    updateSavedJobsCount: (state, action) => {
      state.savedJobsCount = action.payload;
    },
    updateSavedJobsCurrentPage: (state, action) => {
      state.currentSavedJobsPage = action.payload;
    },
    updateFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
    updateSearchRole: (state, action) => {
      state.searchRole = action.payload;
    },
    updateLocationFilter: (state, action) => {
      state.filters.location = action.payload;
    },
    updateSkillsFilter: (state, action) => {
      state.filters.skills = action.payload;
    },
    updateComapniesFilter: (state, action) => {
      state.filters.companies = action.payload;
    },
    updateDomainFilter: (state, action) => {
      state.filters.domain = action.payload;
    },
    updateEmploymentTypeFilter: (state, action) => {
      state.filters.employmentType = action.payload;
    },
    updateWorkExperienceFilter: (state, action) => {
      state.filters.workExperience = action.payload;
    },
    updateWorkStringFilter: (state, action) => {
      state.filters.workString = action.payload;
    },
    updateDisableFilter: (state, action) => {
      state.filters.disableFilter = action.payload;
    },
    resetFilters: (state) => {
      state.filters = {
        workExperience: {},
        location: [],
        disableFilter: true,
        domain: '',
        employmentType: '',
        workString: '',
        jobLocation: '',
        skills: [],
        companies: [],
      };
    },
  },
});

export function fetchAllJobs(params) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const res = await getAllJobs(params);
      res.totalPages = Math.ceil(res.totalCount / 10);
      dispatch(updateJobs(res));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function fetchSavedJobs(params) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const res = await getSavedJobs(params);
      dispatch(updateSavedJobsCount(res?.totalCount));
      dispatch(updateSavedJobs(res));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function fetchSavedJobsCount() {
  return async (dispatch, getState) => {
    try {
      const res = await getSavedJobsCount();
      dispatch(updateSavedJobsCount(res?.noOfSavedJobs));
    } catch (error) {
      console.log(error);
    }
  };
}

export const fetchFilterParams = () => {
  return async (dispatch) => {
    try {
      const result = await getJobParams();
      dispatch(updateFilterParams(result));
    } catch (error) {
      console.error(error);
    }
  };
};

export const {
  updateSavedJobs,
  updateJobs,
  setLoading,
  updateCurrentPage,
  updateSavedJobsCount,
  updateSavedJobsCurrentPage,
  updateFilterParams,
  updateSearchRole,
  updateLocationFilter,
  updateDomainFilter,
  updateEmploymentTypeFilter,
  updateWorkExperienceFilter,
  updateWorkStringFilter,
  updateDisableFilter,
  resetFilters,
  updateJobLocation,
  updateComapniesFilter,
  updateSkillsFilter,
} = jobBoardSlice.actions;

export default jobBoardSlice.reducer;
