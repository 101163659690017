import { Woolf } from '@woolfuniversity/sdk';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { courseSelectedSelector } from 'src/redux/courseSlice';
import { fetchMastersDegreeId, mastersDegreeIdSelector } from 'src/redux/mastersSlice';
import { userSubscriptionSelector } from 'src/redux/userSubscriptionSlice';
import { returnCourseApiName } from '../getcourse';
import { getWoolfToken } from '../userApiService';
import WoolfContext from './woolfContext';

const WoolfProvider = ({ children }) => {
  const [woolfInstance, setWoolfInstance] = useState(null);
  const { courseData } = useSelector((state) => state.learnTabReducer);
  const course = useSelector(courseSelectedSelector);
  const subscription = useSelector(userSubscriptionSelector);
  const dispatch = useDispatch();
  const mastersDegreeId = useSelector(mastersDegreeIdSelector);
  const apiCourseName = returnCourseApiName(course);

  const getWoolfEnv = () => {
    const ENV = process.env.REACT_APP_ENV || 'dev';
    switch (ENV) {
      case 'prod':
      case 'uat':
        return 'university';
      default:
        return 'sandbox';
    }
  };

  const initializeWidget = async (token) => {
    try {
      if (woolfInstance) return;
      const woolf = await Woolf.create(token, {
        widget: { renderOnInit: false },
        env: getWoolfEnv(),
      });
      setWoolfInstance(woolf);
      return woolf;
    } catch (error) {
      console.error('Error initializing Woolf:', error);
    } finally {
      if (!mastersDegreeId?.[apiCourseName]) {
        await dispatch(fetchMastersDegreeId());
      }
    }
  };

  useEffect(async () => {
    async function handleWoolfEvent(event) {
      try {
        if (courseData?.BASIC_INFO && !_.isEmpty(subscription)) {
          const res = await getWoolfToken();
          if (res?.token) {
            await initializeWidget(res?.token);
          }
        }
      } catch (error) {
        console.error('Error initializing Woolf:', error);
      }
    }
    handleWoolfEvent();
  }, [courseData, course, subscription]);

  return <WoolfContext.Provider value={woolfInstance}>{children}</WoolfContext.Provider>;
};

export default WoolfProvider;
