import { getRandom } from '../utils';
import { memo } from 'react';
import Lottie from 'lottie-react';
import logo from './almabetterLoader.json';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <div className='flex w-screen h-screen font-sans'>
      <div className=' space-y-4 items-center text-center m-auto px-2 '>
        <LoadingCircle iconStyle='md:w-16 md:h-16 w-8 h-8 text-blue-500' />
      </div>
    </div>
  );
};

export default Loader;

const LoadingCircle = ({ iconStyle }) => (
  <svg
    className={'inline animate-spin ' + iconStyle}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
  >
    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
    <path
      className='opacity-75'
      fill='currentColor'
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    />
  </svg>
);

const AlmabetterDesign = () => {
  const text = [
    'Doing alchemy..',
    'Creating magic..',
    'Casting spells..',
    'Preparing playground..',
    'Doing science..',
  ];
  return (
    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
      <Lottie animationData={logo} loop={true} className='w-32 h-32' />
      <p className='text-center text-learn-primary'>{getRandom(text)}</p>
    </div>
  );
};

export const DefaultLoadingCenteredCircle = (props) => {
  return (
    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' {...props}>
      <CircularProgress />
    </div>
  );
};

export const LoadingAlmabetterDesign = memo(AlmabetterDesign);
