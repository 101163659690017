import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ugLoadOnboarding: false,
  ugCurrentPage: null,
  ugContinueOnboarding: false,
};

const ugCourseSlice = createSlice({
  name: 'ugReducer',
  initialState,
  reducers: {
    setUgLoadOnboarding(state, action) {
      state.ugLoadOnboarding = action.payload;
    },
    setUgContinueOnboarding(state, action) {
      state.ugContinueOnboarding = action.payload;
    },
    setUgCurrentPage(state, action) {
      state.ugCurrentPage = action.payload;
    },
  },
});

export const { setUgLoadOnboarding, setUgCurrentPage, setUgContinueOnboarding } =
  ugCourseSlice.actions;

export const loadUgOnboarding = (state) => state.ugReducer.ugLoadOnboarding;
export const currentUgState = (state) => state.ugReducer.ugCurrentPage;
export const continueUgOnboarding = (state) => state.ugReducer.ugContinueOnboarding;

export default ugCourseSlice.reducer;
