import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  navigationClassname: '',
  sidebarClassname: '',
  hideCourseSelector: false,
  showExtraHeader: true,
  onBoardingState: false,
  removeHeight: false,
};

const navigationSlice = createSlice({
  name: 'navigationReducer',
  initialState,
  reducers: {
    updateNavigationClassname: (state, action) => {
      state.navigationClassname = action.payload;
    },
    updateSidebarClassname: (state, action) => {
      state.sidebarClassname = action.payload;
    },
    updateCourseSelector: (state, action) => {
      state.hideCourseSelector = action.payload;
    },
    updateShowExtraHeader: (state, action) => {
      state.showExtraHeader = action.payload;
    },
    updateOnBoardingState: (state, action) => {
      state.onBoardingState = action.payload;
    },
    updateRemoveHeight: (state, action) => {
      state.removeHeight = action.payload;
    },
  },
});

export const {
  updateNavigationClassname,
  updateSidebarClassname,
  updateCourseSelector,
  updateShowExtraHeader,
  updateOnBoardingState,
  updateRemoveHeight,
} = navigationSlice.actions;

export default navigationSlice.reducer;
