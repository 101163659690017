import { createSlice } from '@reduxjs/toolkit';
import COLORS from '../globals/COLORS';

const getThemeProperties = (theme) => {
  switch (theme) {
    case 'dark':
      return {
        inputField: {
          default: COLORS.AB_TERTIARY_SHADE_1,
          hover: COLORS.AB_TERTIARY,
          focused: COLORS.AB_TERTIARY,
          disabled: COLORS.AB_TERTIARY_SHADE_1,
        },
        checkbox: {
          default: COLORS.AB_TERTIARY,
          checked: COLORS.AB_TERTIARY,
        },
        select: {
          default: COLORS.AB_TERTIARY_SHADE_1,
        },
        datePicker: {
          primary: COLORS.AB_PRIMARY_SHADE_1,
          secondary: COLORS.AB_TERTIARY,
          tertiary: COLORS.AB_TERTIARY_SHADE_1,
          highlight_1: COLORS.AB_PRIMARY_SHADE_2,
          highlight_2: COLORS.AB_SECONDARY,
        },
      };
    case 'light':
      return {
        inputField: {
          default: COLORS.AB_PRIMARY_SHADE_4,
          hover: COLORS.AB_PRIMARY_SHADE_5,
          focused: COLORS.AB_PRIMARY_SHADE_5,
          disabled: COLORS.AB_PRIMARY_SHADE_4,
        },
        checkbox: {
          default: COLORS.AB_PRIMARY,
          checked: COLORS.AB_BLUE_C1,
        },
        select: {
          default: COLORS.AB_PRIMARY_SHADE_4,
        },
        datePicker: {
          primary: COLORS.AB_TERTIARY_SHADE_1,
          secondary: COLORS.AB_PRIMARY,
          tertiary: COLORS.AB_PRIMARY_SHADE_4,
          highlight_1: COLORS.AB_PRIMARY_SHADE_4,
          highlight_2: COLORS.AB_SECONDARY,
        },
      };

    default:
      break;
  }
};

const currentTheme = localStorage.getItem('color-theme') || 'dark';

const initialState = {
  colorTheme: {
    palette: {
      type: currentTheme,
      ...getThemeProperties(currentTheme),
    },
  },
};

const colorModeSlice = createSlice({
  name: 'colorModeReducer',
  initialState,
  reducers: {
    setColorTheme: (state, action) => {
      state.colorTheme = action.payload;
    },
  },
});

export const { setColorTheme } = colorModeSlice.actions;

export default colorModeSlice.reducer;

export function switchTheme(theme) {
  return async (dispatch) => {
    let palette = {};
    if (theme === 'dark') {
      palette = {
        type: 'dark',
        ...getThemeProperties(theme),
      };
    } else {
      palette = {
        type: 'light',
        ...getThemeProperties(theme),
      };
    }
    dispatch(setColorTheme({ palette }));
  };
}
