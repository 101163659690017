import { API } from 'aws-amplify';
import { LEARN_SUBMISSION_API_ENDPOINT_NAME } from '../aws-exports';
import { getCourse, returnCourseApiName } from './getcourse';

export const getProblems = async (course) => {
  const selectedCourse = getCourse(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/assessment/practice-problems/${selectedCourse}/`
  );
};
export const getCodingAssessment = async (course) => {
  const selectedCourse = getCourse(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/assessment/coding-assessments/${selectedCourse}/`
  );
};

export const getQuizAssessment = async (course) => {
  const courseName = returnCourseApiName(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/assessment/mcq-assessments/${courseName}/`
  );
};

export const getSkillsFilter = async (course) => {
  const selectedCourse = getCourse(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/skills?course=${selectedCourse}`
  );
};
