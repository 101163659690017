import { useState } from 'react';
import WebSocketContext from './webhookContext';

const WebSocketProvider = ({ children }) => {
  const [socketUrl, setSocketUrl] = useState('');
  const [lastMessage, setLastMessage] = useState(null);

  return (
    <WebSocketContext.Provider value={{ socketUrl, lastMessage, setSocketUrl, setLastMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
