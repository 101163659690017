import { createSlice } from '@reduxjs/toolkit';
import { getQuestionLevels } from '../modules/new-mentorship/apiService';

const initialState = {
  modules: [],
  competencies: [],
  concepts: [],
  ratingTemplateByConceptId: {},
};

const questionLevelsSlice = createSlice({
  name: 'questionLevelsReducer',
  initialState,
  reducers: {
    setQuestionLevels: (state, { payload }) => {
      state.modules.push(...(payload || []));
      state.modules.forEach((m) => state.competencies.push(...(m?.Competencies || [])));
      state.competencies.forEach((c) => {
        state.concepts.push(...(c?.Concepts || []));
        c?.Concepts?.forEach(({ id }) => (state.ratingTemplateByConceptId[id] = c?.RatingTemplate));
      });
    },
  },
});

export const { setQuestionLevels } = questionLevelsSlice.actions;

export const questionLevelsSelector = (state) => state.questionLevelsReducer;

export const fetchQuestionLevels = () => {
  return async (dispatch, getState) => {
    const { modules } = questionLevelsSelector(getState());
    if (!modules?.length) dispatch(setQuestionLevels(await getQuestionLevels()));
  };
};

export default questionLevelsSlice.reducer;
