import { createSlice } from '@reduxjs/toolkit';
import { COURSE_TYPE } from 'src/constants';
import { getFreeUserCurrentStage } from 'src/services/freeUser';
import { fetchSubscriptionPlans } from 'src/services/paymentApiService';

const initialState = {
  subscriptionPlans: null,
  separateSubscriptionPlans: null,
  remainingInitialCouponTime: null,
};

const paymentSlice = createSlice({
  name: 'paymentReducer',
  initialState,
  reducers: {
    setSubscriptionPlans: (state, action) => {
      state.subscriptionPlans = action.payload;
    },
    setSeparateSubscriptionPlans: (state, action) => {
      state.separateSubscriptionPlans = action.payload;
    },
    setRemainingInitialCouponTime: (state, action) => {
      state.remainingInitialCouponTime = action.payload;
    },
  },
});

export const getSubscriptionPlans = () => {
  return async (dispatch) => {
    try {
      const result = await fetchSubscriptionPlans();
      const reduced = result.reduce((acc, curr) => {
        const { paymentMode, goalType } = curr;
        if (paymentMode === 'pay_after_placement') {
          acc[goalType] = curr;
        } else if (goalType === COURSE_TYPE.UG_DIPLOMA_CS) {
          acc[goalType] = curr;
        } else if ([COURSE_TYPE.MASTERS_CS, COURSE_TYPE.MASTERS_DS_AS].includes(goalType)) {
          acc[goalType] = curr;
        }
        return acc;
      }, {});
      dispatch(setSubscriptionPlans(result));
      dispatch(setSeparateSubscriptionPlans(reduced));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getRemainingInitialCouponTime = (course) => {
  return async (dispatch) => {
    try {
      const res = await getFreeUserCurrentStage(course);
      const stage = parseInt(res?.stage?.split('-')?.[1]);
      if (stage === 4) {
        dispatch(setRemainingInitialCouponTime(res?.updatedAt));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const { setSubscriptionPlans, setSeparateSubscriptionPlans, setRemainingInitialCouponTime } =
  paymentSlice.actions;

export const selectSubscriptionPlans = (state) => state.paymentReducer.separateSubscriptionPlans;
export const selectAllSubscriptionPlans = (state) => state.paymentReducer.subscriptionPlans;
export const selectRemainingInitialCouponTime = (state) =>
  state.paymentReducer.remainingInitialCouponTime;

export default paymentSlice.reducer;
