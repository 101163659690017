import { createSlice } from '@reduxjs/toolkit';

const courseSlice = createSlice({
  name: 'courseReducer',
  initialState: {
    courseSelected: null,
    userSelectedCourses: [],
    popupStatus: false,
    popupStatusMobile: false,
  },
  reducers: {
    setCourseSelected: (state, action) => {
      state.courseSelected = action.payload;
    },
    setUserSelectedCourses: (state, action) => {
      state.userSelectedCourses = action.payload;
    },
    setPopupStatus: (state, action) => {
      state.popupStatus = action.payload;
    },
    setPopupStatusMobile: (state, action) => {
      state.popupStatusMobile = action.payload;
    },
  },
});

export const { setCourseSelected, setUserSelectedCourses, setPopupStatus, setPopupStatusMobile } =
  courseSlice.actions;

export default courseSlice.reducer;

export const courseSelectedSelector = (state) => state.courseReducer.courseSelected;

export const userSelectedCoursesSelector = (state) => state.courseReducer.userSelectedCourses;

export const popUpStatus = (state) => state.courseReducer.popupStatus;

export const popupStatusMobile = (state) => state.courseReducer.popupStatusMobile;
