import { API } from 'aws-amplify';
import { LEARN_SUBMISSION_API_ENDPOINT_NAME } from '../aws-exports';
import { returnSelectedCourse } from '../modules/course-lms-v2/components/shared/utils';
import { getCourse, returnCourseApiName, returnCourseCamelCaseName } from './getcourse';

export const fetchShortCourseCardData = async (course) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/short-courses?courseType=${course}`
  );
};

export const fetchShortCourseData = async (slug) => {
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/short-courses/fetch?slug=${slug}`);
};

export const postComplimentaryActivityStatus = async (data) => {
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/short-courses/lesson/activity/update-status`,
    {
      body: data,
    }
  );
};

export const courseData = async (course, param) => {
  const selectedCourse = returnSelectedCourse(course);
  let string = `/api/course/v2/contents?course=${selectedCourse}`;
  if (param) {
    string = `/api/course/v2/contents?course=${selectedCourse}&${param}`;
  }
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, string);
};

export const postComplimentaryLastLesson = async (data) => {
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/short-courses/last-lesson/update-status`,
    {
      body: data,
    }
  );
};

export const getComplimentaryLastLesson = async (courseId) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/short-courses/last-lesson/get-status?courseId=${courseId}`
  );
};

export const postLastLesson = async (course) => {
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/last-lesson?courseId=${course.courseId}&courseType=${course?.type}`,
    {
      body: {
        courseId: course.courseId,
        moduleId: course.moduleId,
        chapterId: course.chapterId,
        lessonId: course.lessonId,
        trackId: course.trackId,
      },
    }
  );
};

export const completeLesson = async (course) => {
  const courseName = returnCourseCamelCaseName(course.type);
  return await API.put(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/v2/complete-lesson?courseId=${course.courseId}&courseType=${courseName}`,
    {
      body: {
        lessonId: course.lessonId,
        status: course.status,
      },
    }
  );
};

export const fetchPrepLessonPopupStatus = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/popup/get-status?entityId=${data.id}&entityType=${data.type}`
  );
};

export const postPrepLessonPopupStatus = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/popup/mark-as-shown`, {
    body: {
      entityType: data?.type,
      entityId: typeof data?.id === 'string' ? data?.id : JSON.stringify(data?.id),
    },
  });
};

export const postCompletePrepLesson = async (course) => {
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/short-courses/lesson/update-status`,
    {
      body: {
        lessonId: course.lessonId,
        courseId: course.courseId,
        status: course.status,
      },
    }
  );
};

export const postPopupShown = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/course/mark-popup-shown`, {
    body: {
      entityType: data?.type,
      entityId: typeof data?.id === 'string' ? data?.id : JSON.stringify(data?.id),
    },
  });
};

export const getProblemsById = async (data) => {
  const selectedCourse = getCourse(data?.course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/assessment/coding-assessments/${selectedCourse}/${data?.id}`
  );
};

export const getQuizById = async (data) => {
  const courseName = returnCourseApiName(data.course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/assessment/mcq-assessments/${courseName}/${data?.id}`
  );
};

export const getProjectsById = async (data) => {
  const selectedCourse = getCourse(data?.course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/project/${selectedCourse}/${data?.id}`
  );
};

export const getLastLesson = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/last-lesson?courseId=${data?.courseId}&courseType=${data?.courseType}`
  );
};

export const getAssignmentById = async (assignment) => {
  const selectedCourse = getCourse(assignment?.course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/assignment/${selectedCourse}/${assignment?.id}`
  );
};

export const postAssignmentDataById = async (assignment) => {
  const selectedCourse = getCourse(assignment?.course);
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/assignment/${selectedCourse}/${assignment?.id}`,
    {
      body: {
        link: assignment?.link,
        evaluationStatus: assignment?.evaluationStatus,
      },
    }
  );
};

export const getShortCourseCategory = async (course) => {
  const selectedCourse = getCourse(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/short-course-category/${selectedCourse}`
  );
};

export const fetchMultimediaAssignment = async (data) => {
  const courseName = returnCourseApiName(data?.course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/assignment/${courseName}/${data.id}`
  );
};

export const postMultimediaAssignment = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/assignment/submit`, {
    body: data,
  });
};

// classRoom
export const fetchClassRoomData = async (course) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/classroom/all-classes-activity?courseType=${course}`
  );
};

export const fetchAllClasses = async (course) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/v2/classroom/classes?courseType=${course}`
  );
};

export const fetchPreSignedUrl = async ({ cloudType, itemKey }) =>
  API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/v2/classroom/get-signed-url`, {
    body: {
      cloudType,
      itemKey,
    },
  });

// get classroom video
export const fetchPreSignedUrlFromAWS = async (videoItemKey) => {
  return await fetchPreSignedUrl({ cloudType: 'aws', itemKey: videoItemKey });
};

export const fetchHandoutsForClassroom = async (handoutItemKey) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/classroom/get-signed-url?ItemKey=${handoutItemKey}`
  );
};

export const submitClassroomFeedback = async (body) => {
  return await API.post(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/v2/classroom/student/submit-feedback`,
    {
      body,
    }
  );
};

export const getRecordMapByPageId = async (pageId) => {
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/notion/get-page/${pageId}`);
};

export const selectSpecialisationTrack = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/course/select/tracks`, {
    body: data,
  });
};

export const trackAssesmentForWoolf = async (data) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/assessment/track/activities`, {
    body: data,
  });
};

export const fetchAllActivitiesStatus = async (lessonId) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/lesson/${lessonId}/activities`
  );
};

export const updateLessonActivityStatus = async (body) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/course/lesson/actvities`, {
    body,
  });
};
