import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { MODULES_PATH } from '../../../../route-constants';
import { THINKIFIC_CONSTANTS } from '../../../../constants';
import { getJWTToken } from '../../../../services/userApiService';

const ThinkificSSO = () => {
  const history = useHistory();
  const currentLocation = window.location.href;
  async function checkIfLoggedIn() {
    try {
      await Auth.currentAuthenticatedUser();
      handleRedirect();
    } catch (e) {
      console.error(e);
      history.push(`${MODULES_PATH.AUTH.LOGIN}?redirectTo=${currentLocation}`);
    }
  }

  const handleRedirect = async () => {
    try {
      const { jwtToken } = await getJWTToken();
      const thinkificAuthUrl =
        `${THINKIFIC_CONSTANTS.SSO_LOGIN.URL}?jwt=${jwtToken}&` +
        `error_url=${THINKIFIC_CONSTANTS.SSO_LOGIN.ERROR_URL}`;
      window.location.replace(thinkificAuthUrl);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  return (
    <div
      className='grid place-items-center h-screen w-screen'
      style={{ backgroundColor: '#EBEFF3' }}
    >
      <span
        className='w-10/12 md:w-full text-center font-medium md:text-2xl'
        style={{ color: 'rgb(18, 52, 77)' }}
      >
        Redirecting to thinkific{' '}
        <span className='h-auto text-6xl mx-2 md:text-7xl tracking-wide animate-ping'>...</span>
      </span>
    </div>
  );
};

export default ThinkificSSO;
