import React from 'react';
import { postErrorLog } from './services/userApiService';
import store from './redux/store';

export class MyErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidMount() {
    const storedError = sessionStorage.getItem('errorLog');
    if (storedError) {
      try {
        await postErrorLog(JSON.parse(storedError));
        sessionStorage.removeItem('errorLog');
      } catch (error) {
        console.error('Error logging:', error);
      }
    }
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info.componentStack);

    sessionStorage.setItem(
      'errorLog',
      JSON.stringify({
        error: error.message,
        location: window.location.href,
        errorStack: JSON.stringify(error.stack),
        componentStack: JSON.stringify(info.componentStack),
        reduxStore: {
          ...store.getState()?.authReducer?.loggedInUser,
        },
      })
    );
    const currentCourse = window.location.origin;
    window.location.assign(currentCourse);
  }

  render() {
    return this.props.children;
  }
}
