import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseContent: [],
  moduleContent: [],
  lessonContent: {
    activeTopic: '',
    activeTopicDuration: '',
    activeContent: '',
    activeTopics: '',
    codeResult: '',
    code: '',
    isDefault: '',
    codeError: '',
    topicProblems: '',
    selectedQues: '',
    topicProblemCode: [],
    currentLesson: '',
    quizConfig: [],
    showPreview: false,
    gistUrl: '',
    videoUrl: '',
    language: 'javascript',
    multimedia: [],
  },
};

const courseDetailsSlice = createSlice({
  name: 'courseDetailsReducer',
  initialState,
  reducers: {
    updateCourseContent: (state, action) => {
      state.courseContent = action.payload.content;
    },
    updateModuleContent: (state, action) => {
      state.moduleContent = action.payload.content;
    },
    updateLessonContent: (state, action) => {
      const {
        name,
        content,
        duration,
        course_problems,
        problem_code,
        assessment_config,
        gistUrl,
        videoUrl,
        multimedia,
      } = action.payload.getTopic;
      state.lessonContent.activeTopics = action.payload.courseLessons;
      state.lessonContent.activeTopic = name;
      state.lessonContent.activeContent = content;
      state.lessonContent.activeTopicDuration = parseInt(duration) / 60;
      state.lessonContent.topicProblems = course_problems?.data || [];
      state.lessonContent.topicProblemCode = problem_code;
      state.lessonContent.quizConfig = assessment_config;
      state.lessonContent.currentLesson = action.payload.lessonId;
      state.lessonContent.gistUrl = gistUrl;
      state.lessonContent.videoUrl = videoUrl;
      state.lessonContent.language = action.payload.language;
      state.lessonContent.multimedia = multimedia;
    },
    updatePreview: (state, action) => {
      state.lessonContent.showPreview = true;
    },
  },
});

export const { updateCourseContent, updateModuleContent, updateLessonContent } =
  courseDetailsSlice.actions;

export default courseDetailsSlice.reducer;
