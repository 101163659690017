import { API } from 'aws-amplify';
import { LEARN_SUBMISSION_API_ENDPOINT_NAME } from '../aws-exports';
import { getCourse } from './getcourse';

export const getCoachesChampions = async (course) => {
  const selectedCourse = getCourse(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/career/almax/coach-and-champion/${selectedCourse}/`
  );
};

export const getEgligibilityCriteria = async (course) => {
  const selectedCourse = getCourse(course);
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/career/almax/eligibility-criteria/${selectedCourse}/`
  );
};

export const getCarrerContent = async (course) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/course/almax-content?courseType=${course}`
  );
};

export const getFreeUserCarrer = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/career/prospects-data?courseType=${data?.course}&planType=${data?.plan}`
  );
};

export const applyForAlmaX = async (goalType) => {
  return await API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/career/apply-for-almax/v2`, {
    body: {
      goalType: goalType,
    },
  });
};
