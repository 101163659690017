import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastWrapper = (type, message, position, style = {}, ...props) => {
  switch (type) {
    case 'success':
      toast.success(message, {
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: false,
        draggable: false,
        position: position || toast.POSITION.BOTTOM_RIGHT,
        style: {
          backgroundColor: 'rgb(5, 150, 105)',
          ...style,
        },
        ...props,
      });
      break;
    case 'error':
      toast.error(message, {
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        position: position || toast.POSITION.BOTTOM_RIGHT,
        style,
        ...props,
      });
      break;
    case 'warning':
      toast.warning(message, {
        autoClose: false,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        position: position || toast.POSITION.BOTTOM_RIGHT,
        style,
        ...props,
      });
      break;
    case 'info':
      toast.info(message, {
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: false,
        draggable: false,
        position: position || toast.POSITION.BOTTOM_RIGHT,
        style,
        ...props,
      });
      break;
    default:
      break;
  }
};

export default toastWrapper;
