import { createSlice } from '@reduxjs/toolkit';
import { returnActualCourseNameForApi } from '../services/getcourse';
import {
  getCodingAssessment,
  getQuizAssessment,
  getSkillsFilter,
} from '../services/practiceApiService';

const initialState = {
  localNavigationRoutes: {
    currSelectedTab: 0,
    practiceTabs: {
      coding_problem: 1,
      coding_assesment: 1,
      quiz_assesment: 1,
    },
    lockFilter: {
      coding_problem: 'default',
    },
  },
  codingAssessment: {},
  quiz_assesment: {},
  skillsFilterData: null,
  codingAssessmentProblemList: {
    data: [],
    solvedCount: null,
    popupShown: false,
    assessmentId: null,
    totalProblems: null,
  },
};

const practiceSlice = createSlice({
  name: 'problemDetailReducer',
  initialState,
  reducers: {
    selectTab: (state, action) => {
      state.localNavigationRoutes.practiceTabs[action.payload.currentPracticeTab] =
        action.payload.sub_tab;
    },
    selectLockFilter: (state, action) => {
      state.localNavigationRoutes.lockFilter[action.payload.currentPracticeTab] =
        action.payload.lockFilter;
    },
    selectCurrentTab: (state, action) => {
      state.localNavigationRoutes.currSelectedTab = action.payload.currSelectedTab;
    },
    setCodingAssessment: (state, action) => {
      const compare = (a, b) => {
        if (!a.attributes.isLocked && b.attributes.isLocked) return -1;
        return 0;
      };
      if (!action?.payload?.noComparator) {
        const dataArray = action?.payload?.data;
        dataArray && dataArray.sort(compare);
      }
      state.codingAssessment = action.payload;
    },
    setQuizAssessment: (state, action) => {
      const compare = (a, b) => {
        if (!a.attributes.isLocked && b.attributes.isLocked) return -1;
        return 0;
      };

      const dataArray = action?.payload?.data;
      dataArray && dataArray.sort(compare);

      state.quiz_assesment = action.payload;
    },
    setSkillFilter: (state, action) => {
      state.skillsFilterData = action.payload;
    },
    setCodingAssesmentProblemsList: (state, action) => {
      state.codingAssessmentProblemList = action.payload;
    },
  },
});

export function fetchCodingAssessment() {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)?.[0];
      const actualCourseSelected = getState().courseReducer?.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const codingAssessment = await getCodingAssessment(modifiedCourse);
      dispatch(setCodingAssessment(codingAssessment));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchCodingAssessmentById(currentPath, assessmentId) {
  return async (dispatch, getState) => {
    try {
      if (currentPath) {
        const current = getState().practiceReducer?.codingAssessment?.data?.find(
          (i) => i.id === assessmentId
        );
        const learnProblems = {
          data: current?.attributes?.learn_problems?.data,
          solvedCount: current?.attributes?.solved,
          popupShown: current?.attributes.popupShown,
          assessmentId: assessmentId,
          totalProblems: current?.attributes?.totalProblems,
        };
        dispatch(setCodingAssesmentProblemsList(learnProblems));
        return learnProblems;
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchQuizAssessment() {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const quizAssessment = await getQuizAssessment(modifiedCourse);
      dispatch(setQuizAssessment(quizAssessment));
      return quizAssessment;
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchSkillFilters() {
  return async (dispatch, getState) => {
    try {
      const userSubscription = getState().userSubscriptionReducer.subscriptionDetails;
      const courseSelected = Object.keys(userSubscription)[0];
      const actualCourseSelected = getState().courseReducer.courseSelected;
      const modifiedCourse = returnActualCourseNameForApi(actualCourseSelected || courseSelected);
      const skillFilter = await getSkillsFilter(modifiedCourse);
      dispatch(setSkillFilter(skillFilter));
    } catch (error) {
      console.error(error);
    }
  };
}

export const {
  selectTab,
  setCodingAssessment,
  selectCurrentTab,
  setQuizAssessment,
  setSkillFilter,
  selectLockFilter,
  setCodingAssesmentProblemsList,
} = practiceSlice.actions;

export default practiceSlice.reducer;
