import { createSlice } from '@reduxjs/toolkit';
import {
  updateMyProfile,
  getMyProfile,
  getOnboardingInfo,
  updateOnboardingInfo,
} from '../services/userApiService';

const initialState = {
  isLoggedIn: false,
  loggedInUser: null,
  profileInfo: null,
};

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    updateAuthStatus: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
  },
});

export const { updateAuthStatus, setLoggedInUser, setProfileInfo } = authSlice.actions;

export function updateUser(userObj) {
  return async (dispatch) => {
    try {
      const updatedUser = await updateMyProfile(userObj);
      dispatch(setLoggedInUser(updatedUser));
    } catch (error) {
      console.error(error);
    }
  };
}

export function loadUser() {
  return async (dispatch) => {
    try {
      const user = await getMyProfile();
      dispatch(setLoggedInUser(user));
      dispatch(updateAuthStatus(true));
      dispatch(getProfileInfo());
    } catch (error) {
      console.error(error);
    }
  };
}

export function loadProfileInfo() {
  return async (dispatch) => {
    try {
      const user = await getMyProfile();
      dispatch(setLoggedInUser(user));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getProfileInfo() {
  return async (dispatch) => {
    try {
      const profileInfo = await getOnboardingInfo();
      dispatch(setProfileInfo(profileInfo));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateProfileInfo(updateObj) {
  return async (dispatch, getState) => {
    try {
      const updatedInfo = await updateOnboardingInfo(updateObj);
      dispatch(setProfileInfo({ ...profileInfoSelector(getState()), ...updatedInfo }));
    } catch (error) {
      console.error(error);
    }
  };
}

export const loggedInUserSelector = (state) => state.authReducer.loggedInUser;
export const profileInfoSelector = (state) => state.authReducer.profileInfo;
export const woolfTokenSelector = (state) => state.authReducer.woolfToken;

export default authSlice.reducer;
