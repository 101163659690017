import { PasiaBazzarLogo, WhiteAlmabetterLogo } from '../_assets/logos';
import userLogo from '../_assets/userLogo.svg';
import brandsLogos from '../_assets/brandsLogos.svg';
import { useHistory } from 'react-router-dom';
import { getRedirectTo } from 'src/utils';

function LeftSignupContent({ type }) {
  const history = useHistory();
  return (
    <div
      className='md:col-span-5 md:p-8 md:flex flex-col justify-around gap-8 col-span-12 p-3.5 md:h-auto h-16'
      style={{
        background: 'linear-gradient(166.15deg, #2C2C2C 23.65%, #000000 85.73%)',
      }}
    >
      <div className='md:mb-8 md:block flex justify-between'>
        <WhiteAlmabetterLogo />
        <span
          className='text-learn-primary md:hidden block bg-white cursor-pointer border border-solid border-learn-primary px-4 py-1'
          style={{
            borderRadius: '4px',
          }}
          onClick={() => {
            const redirectUrl = getRedirectTo();
            const key = type ? 'signup' : 'login';
            if (redirectUrl) {
              history.replace(`/auth/${key}?redirectTo=${redirectUrl}`);
            } else {
              history.replace(`/auth/${key}`);
            }
          }}
        >
          {type ? 'Sign Up' : 'Sign In'}
        </span>
      </div>
      <div className='relative md:block hidden'>
        <div
          className='w-32 h-24 rounded-md'
          style={{
            background: 'linear-gradient(126.96deg, #F00037 13.43%, #4D0012 92.46%)',
          }}
        >
          <img src={userLogo} loading='lazy' className='w-40 absolute bottom-full -mb-24 -left-5' />
        </div>
        <div
          className='rounded-md relative bottom-8 left-14 px-6 py-4 lg:w-11/12 w-10/12'
          style={{
            background:
              'linear-gradient(139.16deg, rgba(255, 255, 255, 0.1802) 9.71%, rgba(255, 255, 255, 0.0884) 100.87%)',
            backdropFilter: 'blur(15.0125px)',
          }}
        >
          <h1 style={{ color: '#00E8BB' }} className='text-lg'>
            Kamya Malhotra
          </h1>
          <div className='flex justify-between text-sm text-white items-center'>
            <p>Non Tech Background</p>
            <p className='text-3xl -mb-3.5 relative bottom-3 text-learn-primary xl:right-0 right-4'>
              {' '}
              &#10230;
            </p>
            <p>Data Analyst</p>
            <div className='lg:block hidden'>
              <PasiaBazzarLogo />
            </div>
          </div>
          <div className='text-white mt-4'>
            “AlmaBetter&apos;s curriculum is the best curriculum available online. AlmaBetter&apos;s
            program is engaging, comprehensive, and student-centered. If you&apos;re serious about
            accelerating your tech career, you cannot ask for a better platform than AlmaBetter”.
          </div>
        </div>
      </div>
      <div className='md:grid gap-4 -mt-8 hidden'>
        <h1 className='text-white text-center xl:px-16 lg:px-8'>
          Take a moonshot at your tech career and land a job with one of the top tech companies
        </h1>
        <img src={brandsLogos} loading='lazy' alt='brands' />
      </div>
    </div>
  );
}

export default LeftSignupContent;
